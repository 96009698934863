import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { DragAndDropArea } from 'answers-core/src/components/FilesUpload/DragAndDropArea/DragAndDropArea';
import { FileInput } from 'answers-core/src/components/FilesUpload/FileInput/FileInput';
import { setFilesDraggingOver } from 'answers-core/src/redux/slices/modals/ask-an-expert-modal-slice';
import { Field, FieldProps, Formik } from 'formik';

import {
    useGetSlackChannelsQuery,
    useGetSlackUsersQuery,
    useLazyGetSlackChannelMembersQuery,
} from '../../../api/endpoints/ask-an-expert-endpoint';
import { ISlackMember } from '../../../api/types';
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useProject, useSources } from '../../../redux/hooks/settings-hooks';
import { IAskAnExpertFormData } from '../../../redux/slices/modals/ask-an-expert-modal-slice';
import { closeAskAnExpert, handleAskAnExpertSubmit } from '../../../redux/thunks/ask-an-expert-thunk';
import { Select } from '../../Controls/Select/Select';
import { VirtualizedSelect } from '../../Controls/VirtualizedSelect/VirtualizedSelect';
import { Tooltip } from '../../Tooltips/Tooltip';
import { WithTranslateErrors } from '../../WithTranslateErrors/WithTranslateErrors';
import { ModalContainer } from '../Container/ModalContainer';

import { ACCEPTED_FILE_FORMATS } from './constants';
import { validationSchema } from './validation-schema';

import styles from './AskAnExpertModal.module.scss';

export const AskAnExpertModal: React.FC = () => {
    const { t } = useTranslation('translations');
    const [shouldFetchAllMembers, setShouldFetchAllMembers] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { visible, initialForm } = useAppSelector((state) => state.modals.askAnExpert);
    const { sources } = useSources();
    const { project } = useProject();

    const { data: channels } = useGetSlackChannelsQuery(project, { skip: !project });
    const { data: slackUsers, isFetching: isSlackUsersLoading } = useGetSlackUsersQuery(
        { projectId: project, shouldFetchAllMembers, slackId: initialForm.userId },
        {
            skip: !project,
        }
    );
    const [triggerGetSlackChannelMembers, { data: members, isFetching: isSlackMembersLoading }] =
        useLazyGetSlackChannelMembersQuery();

    const handleCloseModal = () => {
        dispatch(closeAskAnExpert());
    };

    const acceptedFileFormats = ACCEPTED_FILE_FORMATS.join(', ');

    const handleFormSubmit = async (formData: IAskAnExpertFormData) => {
        const meta = {
            sources,
            project,
        };
        await dispatch(handleAskAnExpertSubmit({ formData, meta }));
    };

    const handleSlackChannelChange = useCallback(
        (channelId: string) => {
            project && triggerGetSlackChannelMembers({ projectId: project, channelId });
        },
        [project, triggerGetSlackChannelMembers]
    );

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(setFilesDraggingOver(true));
    };

    return (
        <ModalContainer isOpen={visible} onClose={handleCloseModal} motionPreset="scale" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent className={styles.contentWithScale}>
                <ModalHeader>{t('ask-an-expert.header')}</ModalHeader>
                <Formik
                    initialValues={initialForm}
                    onSubmit={handleFormSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                >
                    {({ errors, touched, setFieldTouched, submitForm, isSubmitting, setFieldValue }) => {
                        return (
                            <WithTranslateErrors errors={errors} touched={touched} setFieldTouched={setFieldTouched}>
                                <ModalBody
                                    className={styles.body}
                                    data-testid="modal.ask-an-expert.body"
                                    onDragEnter={handleDragEnter}
                                >
                                    <Text fontSize="1.4em" color="mid-gray" fontWeight="medium">
                                        {t('ask-an-expert.top-hint')}
                                    </Text>
                                    <Field name="channel">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.channel && touched.channel}>
                                                <FormLabel size="xs">{t('ask-an-expert.channel.label')}</FormLabel>
                                                <Select
                                                    value={field.value}
                                                    options={channels || []}
                                                    onChange={(channelId) => {
                                                        setFieldValue(field.name, channelId);
                                                        channelId &&
                                                            !Array.isArray(channelId) &&
                                                            handleSlackChannelChange(channelId);
                                                        // Experts are related to the channel
                                                        setFieldValue('experts', []);
                                                    }}
                                                    name={field.name}
                                                    placeholder={t('ask-an-expert.channel.placeholder')}
                                                    data-testid="modal.ask-an-expert.channel"
                                                />
                                                <FormErrorMessage>{errors.channel}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="userId">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.userId && touched.userId}>
                                                <div className={styles.warning}>
                                                    <FormLabel size="xs">{t('ask-an-expert.user.label')}</FormLabel>
                                                    {members &&
                                                        !members.map((member) => member.id).includes(field.value) && (
                                                            <Tooltip label={t('ask-an-expert.user.warning')}>
                                                                <AlertIcon />
                                                            </Tooltip>
                                                        )}
                                                </div>
                                                <VirtualizedSelect
                                                    value={field.value}
                                                    isLoading={isSlackUsersLoading}
                                                    handleMenuOpen={() => setShouldFetchAllMembers(true)}
                                                    onChange={(userId) => setFieldValue(field.name, userId)}
                                                    options={slackUsers || []}
                                                    name={field.name}
                                                    placeholder={t('ask-an-expert.user.placeholder')}
                                                    data-testid="modal.ask-an-expert.user"
                                                />
                                                <FormErrorMessage>{errors.userId}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="experts">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.experts && touched.experts}>
                                                <FormLabel size="xs">{t('ask-an-expert.experts.label')}</FormLabel>
                                                <Select
                                                    value={field.value}
                                                    options={
                                                        isSlackMembersLoading
                                                            ? []
                                                            : members?.map((member: ISlackMember) => ({
                                                                  value: member.id,
                                                                  label: member.name,
                                                              })) || []
                                                    }
                                                    isLoading={isSlackMembersLoading}
                                                    onChange={(experts) => setFieldValue(field.name, experts)}
                                                    name={field.name}
                                                    isMulti
                                                    placeholder={t('ask-an-expert.experts.placeholder')}
                                                    data-testid="modal.ask-an-expert.experts"
                                                />
                                                <FormErrorMessage>{errors.experts}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="text">
                                        {({ field }: FieldProps<string>) => (
                                            <FormControl isInvalid={!!errors.text && touched.text}>
                                                <FormLabel size="xs">{t('ask-an-expert.text.label')}</FormLabel>
                                                <Textarea
                                                    variant="outline"
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    placeholder={t('ask-an-expert.text.placeholder')}
                                                    className={styles.textarea}
                                                    data-testid="modal.ask-an-expert.text"
                                                />
                                                <FormErrorMessage>{errors.text}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="files">
                                        {({ field, form, meta }: FieldProps<File[]>) => {
                                            return (
                                                <>
                                                    <FileInput
                                                        field={field}
                                                        form={form}
                                                        meta={meta}
                                                        tooltip={t('ask-an-expert.supported-files')}
                                                        data-testid={'modal.ask-an-expert.files'}
                                                        accept={acceptedFileFormats}
                                                    />
                                                    <DragAndDropArea
                                                        onFileDrop={(droppedFiles: File[]) => {
                                                            const currentFiles = field.value || [];
                                                            const updatedFiles = [...currentFiles, ...droppedFiles];
                                                            form.setFieldValue(field.name, updatedFiles);
                                                        }}
                                                    ></DragAndDropArea>
                                                </>
                                            );
                                        }}
                                    </Field>
                                    <Text fontSize="1.1em" color="light-gray" fontWeight="medium">
                                        {t('ask-an-expert.bottom-hint')}
                                    </Text>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        variant="secondary"
                                        onClick={handleCloseModal}
                                        data-testid="modal.ask-an-expert.cancel"
                                    >
                                        {t('button.cancel')}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={submitForm}
                                        isLoading={isSubmitting}
                                        data-testid="modal.ask-an-expert.submit"
                                    >
                                        {t('button.submit')}
                                    </Button>
                                </ModalFooter>
                            </WithTranslateErrors>
                        );
                    }}
                </Formik>
            </ModalContent>
        </ModalContainer>
    );
};
