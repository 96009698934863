import { isAnyOf, Middleware } from '@reduxjs/toolkit';

import { environmentBackend } from '../../api/consts';
import { ILastAnswersData } from '../../api/types';
import { initEventBus } from '../../services/core/event-bus/event-bus';
import { initializeResourcesService } from '../../services/resources/resources-service';
import {
    BACKEND_URL_STORAGE_KEY,
    FLOATING_ICON_POSITION_STORAGE_KEY,
    LANGUAGE_CODE_STORAGE_KEY,
    LAST_ANSWERS_STORAGE_KEY,
    ONLINE_MEMORY_STORAGE_KEY,
    ORIGINS_BLACKLIST_STORAGE_KEY,
    PROACTIVE_POPUP_SHOWED_FOR,
    PROACTIVE_VIEW_HISTORY_STORAGE_KEY,
    PROJECT_STORAGE_KEY,
    SEARCH_STATE_RELEVANCE_TIME_STORAGE_KEY,
    SELECTED_SMART_FILTERS_KEY,
    SHOULD_DISPLAY_FLOATING_APP,
    SOURCES_STORAGE_KEY,
    TOKEN_STORAGE_KEY,
} from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { getPermissionsFromRoles } from '../../utils/permissions';
import { prepareShowedForMap } from '../../utils/proactive-answers/proactive-answer';
import { limitFloatingIconYPositionV2 } from '../../utils/ui';
import { DEFAULT_SEARCH_STATE_RELEVANCE_TIME } from '../constants';
import { IRootState } from '../core-store';
import { replaceState } from '../helpers';
import { setLoading } from '../slices/app/app-slice';
import { FiltersTabIndex } from '../types';

import { getTokenAndRoles } from './helpers/get-token';
import { subscribeToTokenUpdate } from './helpers/subscribe-to-token-update';

export const appInitMiddleware: Middleware<{}, IRootState> = (store) => (next) => (action) => {
    const { dispatch, getState } = store;

    const { initialized, loading } = getState().app;

    const serviceAction = isAnyOf(setLoading);

    if (!initialized && !loading && !serviceAction(action)) {
        dispatch(setLoading(true));

        storageService
            .getStorageValues([
                TOKEN_STORAGE_KEY,
                LAST_ANSWERS_STORAGE_KEY,
                SOURCES_STORAGE_KEY,
                BACKEND_URL_STORAGE_KEY,
                PROJECT_STORAGE_KEY,
                ONLINE_MEMORY_STORAGE_KEY,
                SHOULD_DISPLAY_FLOATING_APP,
                ORIGINS_BLACKLIST_STORAGE_KEY,
                FLOATING_ICON_POSITION_STORAGE_KEY,
                SEARCH_STATE_RELEVANCE_TIME_STORAGE_KEY,
                PROACTIVE_VIEW_HISTORY_STORAGE_KEY,
                LANGUAGE_CODE_STORAGE_KEY,
                PROACTIVE_POPUP_SHOWED_FOR,
                SELECTED_SMART_FILTERS_KEY,
            ])
            .then(async (values) => {
                await initEventBus();
                await initializeResourcesService();

                const { token, roles } = await getTokenAndRoles({
                    previousToken: values[TOKEN_STORAGE_KEY.getValue()],
                });
                const permissions = getPermissionsFromRoles(roles);
                subscribeToTokenUpdate(dispatch);

                const state = getState();

                let lastQuestion = values[LAST_ANSWERS_STORAGE_KEY.getValue()] as Partial<ILastAnswersData> | null;
                const searchStateRelevanceTime =
                    (values[SEARCH_STATE_RELEVANCE_TIME_STORAGE_KEY.getValue()] as number | null) ??
                    DEFAULT_SEARCH_STATE_RELEVANCE_TIME;

                // in case searchStateRelevanceTime is set to 0, we don't want to clear the last question by time
                if (lastQuestion?.timestamp && searchStateRelevanceTime) {
                    const now = Date.now();
                    const isRelevant = now - lastQuestion.timestamp <= searchStateRelevanceTime;
                    if (!isRelevant) {
                        lastQuestion = null;
                        await storageService.removeStorageValue(LAST_ANSWERS_STORAGE_KEY);
                    }
                }

                const newState: IRootState = {
                    ...state,
                    app: {
                        ...state.app,
                        initialized: true,
                        loading: false,
                        error: false,
                    },
                    auth: {
                        ...state.auth,
                        token,
                        roles,
                        permissions,
                    },
                    question: {
                        ...state.question,
                        answers: lastQuestion?.answers ?? [],
                        question: lastQuestion?.question ?? '',
                        question_id: lastQuestion?.question_id ?? '',
                        expect_direct_answer: Boolean(lastQuestion?.expect_direct_answer),
                        action_type: lastQuestion?.action_type ?? null,
                        custom_action: lastQuestion?.custom_action ?? null,
                        proactiveMeta: lastQuestion?.proactiveMeta ?? null,
                        proactivelyGenerated: !!lastQuestion?.proactiveMeta,
                    },
                    settings: {
                        ...state.settings,
                        searchStateRelevanceTime,
                        disabledSources: values[SOURCES_STORAGE_KEY.getValue()] ?? [],
                        backendUrl: values[BACKEND_URL_STORAGE_KEY.getValue()] ?? environmentBackend,
                        project: values[PROJECT_STORAGE_KEY.getValue()] ?? '',
                        disableOnlineMemory: values[ONLINE_MEMORY_STORAGE_KEY.getValue()] ?? null,
                        shouldDisplayFloatingApp: Boolean(values[SHOULD_DISPLAY_FLOATING_APP.getValue()] ?? true),
                        originsBlacklist: values[ORIGINS_BLACKLIST_STORAGE_KEY.getValue()] ?? [],
                        simulateCustomerProject: values[PROJECT_STORAGE_KEY.getValue()] ?? '',
                        languageCode: values[LANGUAGE_CODE_STORAGE_KEY.getValue()] ?? state.settings.languageCode,
                        selectedSmartFilters: values[SELECTED_SMART_FILTERS_KEY.getValue()] ?? [],
                        filtersActiveTab: FiltersTabIndex.SOURCES,
                    },
                    floatingIcon: {
                        ...state.floatingIcon,
                        yPosition: values[FLOATING_ICON_POSITION_STORAGE_KEY.getValue()]
                            ? limitFloatingIconYPositionV2(values[FLOATING_ICON_POSITION_STORAGE_KEY.getValue()])
                            : null,
                    },
                    proactiveAnswers: {
                        ...state.proactiveAnswers,
                        showedFor: prepareShowedForMap(values[PROACTIVE_POPUP_SHOWED_FOR.getValue()]),
                    },
                };

                dispatch(replaceState(newState));
            });
    }

    return next(action);
};
