import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { closeDirectAnswerChatModal } from '../../redux/slices/modals/direct-answer-chat-modal-slice';
import { closeDirectAnswerEditModal } from '../../redux/slices/modals/direct-answer-edit-modal-slice';
import { abortAskQuestion } from '../../redux/thunks/ask-thunk';
import { abortProactiveQuestion } from '../../redux/thunks/proactive-answer-thunk';
import { clearQuestion } from '../../redux/thunks/question-thunk';
import { LAST_ANSWERS_STORAGE_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { getToastService } from '../../services/toast/toast-service';
import { getUserProject } from '../../utils/user';
import { baseApi } from '../base-api';
import {
    ActionTypeV2,
    ApiTagTypes,
    IActionRequestParams,
    IGetAction,
    ILastAnswersData,
    ResponseStatus,
} from '../types';

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

const isCustomActionType = (actionType: IActionRequestParams['action_type']) => ActionTypeV2.CUSTOM === actionType;

export const actionEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        getAction: builder.mutation<IGetAction, IActionRequestParams>({
            queryFn: async (arg, { getState, dispatch }, extraOptions, baseQuery) => {
                const state = getState() as IRootState;
                const { fullTicketAppUrl, simulateCustomerProject } = state.settings;
                const { question, action_type, custom_action, url } = arg;
                const apiUrl = isCustomActionType(action_type) ? '/v2/ai-helper/action' : '/v1/ai-helper/action';

                // if fullTicketAppUrl is set, use the simulated customer project instead of the actual user project
                const project = fullTicketAppUrl ? simulateCustomerProject : getUserProject(state);

                // We want to abort running proactive process when a user is pressing an ai helper app
                dispatch(abortProactiveQuestion());
                dispatch(abortAskQuestion());
                dispatch(closeDirectAnswerChatModal());
                dispatch(closeDirectAnswerEditModal());

                const actionResponse = await baseQuery({
                    url: apiUrl,
                    method: 'POST',
                    body: {
                        question,
                        url,
                        action_type,
                        customer_name: project,
                        customer_project_id: project,
                        ...(custom_action && { action_id: custom_action?.action_id }),
                    },
                });

                const { data, error, meta } = actionResponse as QueryReturnValue<
                    IGetAction,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.answers', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    dispatch(clearQuestion());

                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                } else {
                    const lastAnswersData: ILastAnswersData = {
                        question: '',
                        answers: [],
                        question_id: data.question_id || '',
                        expect_direct_answer: true,
                        action_type,
                        custom_action: custom_action || null,
                        proactiveMeta: null,
                    };

                    await storageService.setStorageValue(LAST_ANSWERS_STORAGE_KEY, lastAnswersData);

                    return {
                        data: {
                            answers: [],
                            expect_direct_answer: true,
                            question_id: data.question_id || '',
                        },
                    };
                }
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
    }),
});

export const { useGetActionMutation } = actionEndpoint;
