import React, { useCallback } from 'react';
import { ModalContent, ModalOverlay } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useSmartFilters } from '../../../redux/hooks/settings-hooks';
import { closeSourcesFilterModal } from '../../../redux/slices/modals/sources-filter-modal-slice';
import { ModalContainer } from '../Container/ModalContainer';

import { FiltersForm } from './FiltersForm';
import { SourcesFilterForm } from './SourcesFilterForm';

export const SourcesFilterModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const visible = useAppSelector((state) => state.modals.sourcesFilter.visible);
    const { canUseSmartFilters } = useSmartFilters();

    const handleCloseModal = useCallback(() => {
        dispatch(closeSourcesFilterModal());
    }, [dispatch]);

    return (
        <ModalContainer
            closeOnEsc
            closeOnOverlayClick
            isOpen={visible}
            onClose={handleCloseModal}
            motionPreset="scale"
            scrollBehavior="inside"
            variant="sources-filter"
        >
            <ModalOverlay />
            <ModalContent>{canUseSmartFilters ? <FiltersForm /> : <SourcesFilterForm />}</ModalContent>
        </ModalContainer>
    );
};
