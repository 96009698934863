import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalBody, ModalContent as ChakraModalContent, ModalFooter, ModalHeader } from '@chakra-ui/react';
import { Formik } from 'formik';

import { IUseSource } from '../../../api/types';
import { useAnswers } from '../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useSources } from '../../../redux/hooks/settings-hooks';
import { closeSourcesFilterModal } from '../../../redux/slices/modals/sources-filter-modal-slice';
import { getActiveSources } from '../../../utils/transforms';

import { ListOfSources } from './ListOfSources/ListOfSources';
import { getSelectedSourcesCount } from './helpers';

export const FILTER_KEY = '__FILTER__' as const;

export type IFormValues = {
    [FILTER_KEY]: string;
    sources: IUseSource[];
};
const initialValues: IFormValues = {
    [FILTER_KEY]: '',
    sources: [],
};

export const SourcesFilterForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const [answer] = useAnswers();
    const question = useAppSelector((state) => state.question.question);

    const { t } = useTranslation('translations');
    const { sources, disableSources } = useSources();

    const handleCloseModal = useCallback(() => {
        dispatch(closeSourcesFilterModal());
    }, [dispatch]);

    const setDisabledSources = useCallback(
        async (sources: IUseSource[]) => {
            const sourcesToDisable = sources.filter((source) => !source.active);

            return disableSources(sourcesToDisable.map((source) => source.id));
        },
        [disableSources]
    );

    const askWithNewSources = useCallback(
        (sources: IUseSource[]) => {
            if (!question) {
                return;
            }
            const active = getActiveSources(sources);
            answer({ sources: active });
        },
        [question, answer]
    );

    const handleSubmit = useCallback(
        async (formValues: IFormValues) => {
            handleCloseModal();

            const newSources = await setDisabledSources(formValues.sources);

            askWithNewSources(newSources);
        },
        [handleCloseModal, setDisabledSources, askWithNewSources]
    );

    const initialFormValues: IFormValues = useMemo(() => {
        return {
            ...initialValues,
            sources: [...sources],
        };
    }, [sources]);

    return (
        <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
            {({ submitForm, isSubmitting, values }) => {
                const isSubmitDisabled = isSubmitting || getSelectedSourcesCount(values.sources) === 0;

                return (
                    <ChakraModalContent>
                        <ModalHeader h="auto">{t('select-sources.modal.header')}</ModalHeader>
                        <ModalBody p={0} position="relative">
                            <ListOfSources />
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={handleCloseModal} isDisabled={isSubmitting}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={submitForm}
                                isLoading={isSubmitting}
                                isDisabled={isSubmitDisabled}
                            >
                                {t('button.apply')}
                            </Button>
                        </ModalFooter>
                    </ChakraModalContent>
                );
            }}
        </Formik>
    );
};
