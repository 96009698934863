import React, { MouseEvent, MouseEventHandler, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Divider } from '@chakra-ui/react';
import cx from 'clsx';
import { useFormikContext } from 'formik';

import { IUseSource } from '../../../../api/types';
import { CheckBox } from '../../../Controls/CheckBox/CheckBox';
import { getSelectedSourcesCount } from '../helpers';
import { SearchInput } from '../SearchInput/SearchInput';
import { FILTER_KEY, IFormValues } from '../SourcesFilterForm';

import { SourceLabel } from './SourceLabel/SourceLabel';

import styles from './ListOfSources.module.scss';
import listOfSourcesStyles from './ListOfSources.module.scss';
import sourceLabelStyles from './SourceLabel/SourceLabel.module.scss';

export const ListOfSources: React.FC = () => {
    const { t } = useTranslation('translations');
    const { values, setFieldValue } = useFormikContext<IFormValues>();
    const sources = values.sources;
    const filterValue = values[FILTER_KEY].trim();

    const selectedState = useMemo(() => {
        return { total: sources.length, selected: getSelectedSourcesCount(sources) };
    }, [sources]);

    const sourcesToRender = useMemo(() => {
        if (!filterValue) {
            return sources;
        }

        return sources.filter((source) => source.type.toLowerCase().includes(filterValue.toLowerCase()));
    }, [sources, filterValue]);

    const isAllSelected = selectedState.selected > 0 && selectedState.selected === selectedState.total;
    const isIndeterminate = selectedState.selected > 0 && selectedState.selected < selectedState.total;

    const selectAll = useCallback(() => {
        sources.forEach((source, idx) => {
            setFieldValue(`sources[${idx}].active`, true);
        });
    }, [setFieldValue, sources]);

    const deselectAll = useCallback(() => {
        sources.forEach((source, idx) => {
            setFieldValue(`sources[${idx}].active`, false);
        });
    }, [setFieldValue, sources]);

    const handleSelectedOne = useCallback(
        (sourceIdx: number, sources: IUseSource[]) => {
            sources.forEach((source, idx) => {
                if (sourceIdx === idx) {
                    setFieldValue(`sources[${idx}].active`, true);
                } else {
                    setFieldValue(`sources[${idx}].active`, false);
                }
            });
        },
        [setFieldValue]
    );

    const handleClearAllClick: MouseEventHandler = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            deselectAll();
        },
        [deselectAll]
    );

    return (
        <>
            <div className={styles.header}>
                <div className={styles.selectAllWrapper}>
                    <SearchInput />
                    <div className={styles.selectedWrapper}>
                        <CheckBox
                            className={listOfSourcesStyles.checkBox}
                            isChecked={isAllSelected}
                            isIndeterminate={isIndeterminate}
                            onChange={isAllSelected ? deselectAll : selectAll}
                        >
                            <span className={sourceLabelStyles.text}>
                                <Trans
                                    t={t}
                                    i18nKey="select-sources.selected-count"
                                    values={selectedState}
                                >{`${selectedState.selected} of ${selectedState.total} selected`}</Trans>
                            </span>
                        </CheckBox>
                        <button className={listOfSourcesStyles.action} onClick={handleClearAllClick}>
                            {t('select-sources.clear-all')}
                        </button>
                    </div>
                </div>
                <Divider />
            </div>

            <ul className={styles.list}>
                {sourcesToRender.map((source, idx, initArray) => {
                    return (
                        <li className={styles.listItem} key={source.id}>
                            <CheckBox
                                className={styles.checkBox}
                                isChecked={source.active}
                                onChange={() => setFieldValue(`sources[${idx}]`, { ...source, active: !source.active })}
                            >
                                <SourceLabel source={source} />
                            </CheckBox>
                            <button
                                className={cx(styles.action, styles.actionSelectOnly)}
                                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    handleSelectedOne(idx, initArray);
                                }}
                            >
                                {t('select-sources.list-item.show-only')}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
