import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { IAnswerResponse } from '../../../api/types';
import { setDisableOnlineMemory, setProject } from '../../thunks/settings-thunk';
import { logout } from '../../thunks/user-thunk';
import { IAnswersState, INextPayload } from '../../types';

const initialState: IAnswersState = {
    searchFocused: true,
    activeAnswerUuid: '',
    prevAnswerUuid: '',
    collapsed: true,
    analyticsSent: false,
};

export const answersSlice = createSlice({
    name: 'answers',
    initialState,
    reducers: {
        setSearchFocused(state, action: PayloadAction<boolean>) {
            const { payload: focused } = action;

            state.searchFocused = focused;
            if (focused) {
                state.activeAnswerUuid = '';
                state.prevAnswerUuid = '';
            }
        },
        setPreviousAnswerUuid(state, action: PayloadAction<string>) {
            state.prevAnswerUuid = action.payload;
        },
        clearFocus(state) {
            state.searchFocused = false;
            state.activeAnswerUuid = '';
        },
        setCollapsed(state, action: PayloadAction<boolean>) {
            state.collapsed = action.payload;
        },
        setAnalyticsSent(state, action: PayloadAction<boolean>) {
            state.analyticsSent = action.payload;
        },
        nextAnswer(state, action: PayloadAction<INextPayload>) {
            const {
                payload: { answers },
            } = action;

            if (answers.length === 0) {
                return;
            }

            const currentAnswerIndex = answers.findIndex((answer) => answer.uuid === state.activeAnswerUuid);

            if (currentAnswerIndex === answers.length - 1) {
                return;
            }

            const nextAnswer = answers[currentAnswerIndex + 1];

            if (nextAnswer.isLowConfidence) {
                state.collapsed = false;
            }

            state.activeAnswerUuid = nextAnswer.uuid;
            state.searchFocused = false;
        },
        prevAnswer(state, action: PayloadAction<IAnswerResponse[]>) {
            const { payload: answers } = action;

            if (answers.length === 0) {
                return;
            }

            const currentAnswerIndex = answers.findIndex((answer) => answer.uuid === state.activeAnswerUuid);

            if (currentAnswerIndex <= 0) {
                state.activeAnswerUuid = '';
                state.searchFocused = true;
            } else {
                state.activeAnswerUuid = answers[currentAnswerIndex - 1].uuid;
                state.searchFocused = false;
            }
        },
        setActiveAnswer(state, action: PayloadAction<string>) {
            state.searchFocused = false;
            state.activeAnswerUuid = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isClearLastPreviewAction, () => {
            return initialState;
        });
    },
});

export const isClearLastPreviewAction = isAnyOf(
    logout.fulfilled,
    setProject.fulfilled,
    setDisableOnlineMemory.fulfilled
);

export const {
    setSearchFocused,
    setPreviousAnswerUuid,
    clearFocus,
    setCollapsed,
    setAnalyticsSent,
    prevAnswer,
    nextAnswer,
    setActiveAnswer,
} = answersSlice.actions;
