import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text } from '@chakra-ui/react';

import { ReactComponent as PlaceholderIcon } from '../../../assets/icons/no-results-placeholder.svg';
import { DEFAULT_SELECTED_SMART_FILTERS } from '../../../redux/constants';
import { useAnswers } from '../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useSmartFilters, useSources } from '../../../redux/hooks/settings-hooks';
import { sendMetrics } from '../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { getActiveSources } from '../../../utils/transforms';

import styles from './NoRelevantResultsPlaceholder.module.scss';

export const NoRelevantResultsPlaceholder: React.FC = () => {
    const { t } = useTranslation('translations');
    const question = useAppSelector((state) => state.question.question);
    const { activeSmartFiltersLength, clearSmartFilters } = useSmartFilters();
    const [answer] = useAnswers();
    const { sources } = useSources();
    const dispatch = useAppDispatch();
    const [questionToDisplay] = useState<string>(question);

    const handleCLick = async () => {
        const activeSources = getActiveSources(sources);
        dispatch(
            sendMetrics({
                event: MixpanelEvent.SMART_FILTERS_CLEAR_BUTTON,
                meta: {
                    sources: activeSources,
                },
            })
        );
        await clearSmartFilters();
        answer({ sources: activeSources, smartFiltersIds: DEFAULT_SELECTED_SMART_FILTERS });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.results}>
                <PlaceholderIcon />
                <Heading as="h1" size="h2" className={styles.heading}>
                    {t('placeholder.no-relevant-results')}
                </Heading>
                <Heading as="h2" size="h2" className={styles.question} color="text-control" title={questionToDisplay}>
                    {questionToDisplay}
                </Heading>
            </div>
            {activeSmartFiltersLength ? (
                <Button variant="secondary" onClick={handleCLick}>
                    {t('no-relevant-results.clear-smart-filters-button')}
                </Button>
            ) : (
                <Text as="span" fontSize="1.3em" fontWeight={400}>
                    {t('placeholder.no-relevant-results.explanation')}
                </Text>
            )}
        </div>
    );
};
