import React from 'react';
import { useFlag } from '@unleash/proxy-client-react';

import { AppToggle } from '../../clients/unleash/app-toggle-names';

import { AnnotateAnswerModal } from './Annotate/Answer/AnnotateAnswerModal';
import { AnnotatorTagNameModal } from './AnnotatorTagName/AnnotatorTagNameModal';
import { AnswerFeedbackModal } from './Answer/Feedback/AnswerFeedbackModal';
import { AskAnExpertModal } from './AskAnExpert/AskAnExpertModal';
import { DirectAnswerFeedbackModal } from './DirectAnswer/Feedback/DirectAnswerFeedbackModal';
import { FeedbackModal } from './Feedback/FeedbackModal';
import { PointsModal } from './Points/PointsModal';
import { PreviewModal } from './Preview/PreviewModal';
import { RequestKnowledgeModal } from './RequestKnowledge/RequestKnowledge';
import { SettingsModal } from './Settings/SettingsModal';
import { SourcesFilterModal } from './SourcesFilter/SourcesFilter';
import { SuccessModal } from './Success/SuccessModal';
import { WebsitesBlacklist } from './WebsitesBlacklist/WebsitesBlacklist';

export const Modals: React.FC = () => {
    const showAskAnExpert = useFlag(AppToggle.ASK_AN_EXPERT);
    return (
        <>
            <PreviewModal />
            <AnswerFeedbackModal />
            <DirectAnswerFeedbackModal />
            <FeedbackModal />
            <AnnotateAnswerModal />
            <RequestKnowledgeModal />
            <SettingsModal />
            <WebsitesBlacklist />
            <PointsModal />
            <SuccessModal />
            <AnnotatorTagNameModal />
            {showAskAnExpert && <AskAnExpertModal />}
            <SourcesFilterModal />
        </>
    );
};
