import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRootState } from 'answers-core/src/redux/core-store';

import { ChatType, Message } from '../../api/types';
import { CHAT_TAB_CONVERSATION_KEY, DIRECT_ANSWER_CONVERSATION_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { IConversationStorage } from '../types';

interface INewMessage {
    message: Message;
    chatType: ChatType;
    conversationId: string;
}

interface INewConversation {
    conversationSetup: Message[];
    conversationId: string;
    chatType: ChatType;
}

interface IConversationArgs {
    conversationId: string;
    chatType: ChatType;
}

export const addMessage = createAsyncThunk('chat/addMessage', async (payload: INewMessage, { getState }) => {
    const { conversationId, message, chatType } = payload;
    const key = chatType === ChatType.CHAT_TAB ? CHAT_TAB_CONVERSATION_KEY : DIRECT_ANSWER_CONVERSATION_KEY;
    const conversation = (getState() as IRootState).chat[conversationId];
    await storageService.setStorageValue<IConversationStorage | undefined>(key, {
        conversation: [...conversation, message],
        conversation_id: conversationId,
    });
    return payload;
});

export const storeConversation = createAsyncThunk(
    'chat/storeConversation',
    async (args: IConversationArgs, { getState }) => {
        const { conversationId, chatType } = args;
        const conversation = (getState() as IRootState).chat[conversationId];
        const key = chatType === ChatType.CHAT_TAB ? CHAT_TAB_CONVERSATION_KEY : DIRECT_ANSWER_CONVERSATION_KEY;
        await storageService.setStorageValue<IConversationStorage | undefined>(key, {
            conversation,
            conversation_id: conversationId,
        });
    }
);

export const clearConversation = createAsyncThunk('chat/clearConversation', async (args: IConversationArgs) => {
    const { conversationId, chatType } = args;
    const key = chatType === ChatType.CHAT_TAB ? CHAT_TAB_CONVERSATION_KEY : DIRECT_ANSWER_CONVERSATION_KEY;
    await storageService.removeStorageValue(key);
    return conversationId;
});

export const setupConversation = createAsyncThunk('chat/setupConversation', async (payload: INewConversation) => {
    const { conversationId, conversationSetup, chatType } = payload;
    const key = chatType === ChatType.CHAT_TAB ? CHAT_TAB_CONVERSATION_KEY : DIRECT_ANSWER_CONVERSATION_KEY;
    const storage = await storageService.getStorageValue<IConversationStorage | undefined>(key, undefined);
    if (!storage || storage.conversation_id !== conversationId) {
        return {
            conversation: conversationSetup,
            conversationId,
        };
    }
    return { conversation: storage.conversation, conversationId: storage.conversation_id };
});

export const getCurrentConversationId = async (chatType: ChatType) => {
    const key = chatType === ChatType.CHAT_TAB ? CHAT_TAB_CONVERSATION_KEY : DIRECT_ANSWER_CONVERSATION_KEY;
    const storage = await storageService.getStorageValue<IConversationStorage | undefined>(key, undefined);
    return storage?.conversation_id;
};
