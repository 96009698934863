import { createSlice } from '@reduxjs/toolkit';

interface IDirectAnswerEditModalState {
    visible: boolean;
    actionId?: string;
}

const initialState: IDirectAnswerEditModalState = {
    visible: false,
};

export const directAnswerChatModalSlice = createSlice({
    name: 'direct-answer-chat-modal',
    initialState,
    reducers: {
        openDirectAnswerChatModal(state, action) {
            if (!action.payload) {
                return;
            }
            state.actionId = action.payload;
            state.visible = true;
        },
        closeDirectAnswerChatModal(state) {
            state.visible = false;
            state.actionId = undefined;
        },
    },
});

export const { openDirectAnswerChatModal, closeDirectAnswerChatModal } = directAnswerChatModalSlice.actions;
