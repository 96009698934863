import { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';

import { SHARED_PREVIEW_CACHE_KEY } from '../../../api/consts';
import { useGetAnnotateAnswersMutation } from '../../../api/endpoints/annotate-answers-endpoint';
import { useGetAnswersMutation } from '../../../api/endpoints/ask-endpoint';
import { useGetPreviewMutation } from '../../../api/endpoints/preview-endpoint';
import { ActionType, IAnswerResponse, IAskRequestParams } from '../../../api/types';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { isAnnotateWebMode } from '../../../utils/extension-mode';
import { getActiveSmartFiltersIds } from '../../../utils/transforms';
import { SHARED_ANSWERS_CACHE_KEY } from '../../constants';
import { setAnalyticsSent } from '../../slices/answers/answers-slice';
import { setIsTimerEnabled } from '../../slices/proactive-answers/proactive-answers-slice';
import { setIsInitial } from '../../slices/question/question-slice';
import { sendMetrics } from '../../thunks/metrics-thunk';
import { useAppDispatch, useAppSelector } from '../app-hooks';
import { useSmartFilters, useSources } from '../settings-hooks';

import { extractUrlFromPreview } from './preview-url-extractors';

const useGetAnswersHook = isAnnotateWebMode() ? useGetAnnotateAnswersMutation : useGetAnswersMutation;

export const useAnswers = () => {
    const dispatch = useAppDispatch();
    const { smartFilters, canUseSmartFilters } = useSmartFilters();
    const { answers, question_id, expect_direct_answer, proactiveMeta } = useAppSelector(
        (state) => state.question,
        (prev, next): boolean => {
            return (
                prev.answers === next.answers &&
                prev.question_id === next.question_id &&
                prev.expect_direct_answer === next.expect_direct_answer &&
                prev.proactiveMeta === next.proactiveMeta
            );
        }
    );
    const isUatMode = useAppSelector((state) => state.uat.enabled);
    const isInitial = useAppSelector((state) => state.question.isInitial);

    const [answer, { data, isLoading, isUninitialized, reset: resetAnswers }] = useGetAnswersHook({
        fixedCacheKey: SHARED_ANSWERS_CACHE_KEY,
    });
    const { sources } = useSources();

    const ask = useCallback(
        async (params: IAskRequestParams) => {
            try {
                batch(() => {
                    dispatch(setAnalyticsSent(false));
                    dispatch(setIsTimerEnabled(false));
                    dispatch(setIsInitial(false));
                });

                const selectedSmartFiltersIds = canUseSmartFilters
                    ? params.smartFiltersIds || getActiveSmartFiltersIds(smartFilters)
                    : undefined;

                const result = await answer({
                    sources: params.sources,
                    isAiHelper: params.isAiHelper,
                    smartFiltersIds: selectedSmartFiltersIds,
                }).unwrap();

                dispatch(
                    sendMetrics({
                        event: params.isAiHelper ? MixpanelEvent.AI_HELPER : MixpanelEvent.ASK,
                        meta: {
                            sources: params.sources,
                            action_type: params.isAiHelper ? ActionType.SEARCH : undefined,
                            url: params.isAiHelper ? window.location.href : undefined,
                            uat_mode: isUatMode,
                        },
                        injectAnswersMetrics: {
                            sources,
                            answers: result,
                        },
                    })
                );
            } catch (err) {
                const error = err as Error;

                if (error.name === 'AbortError') {
                    return;
                }

                resetAnswers();
            }
        },
        [dispatch, answer, sources, resetAnswers, isUatMode, canUseSmartFilters, smartFilters]
    );

    const lastAnswers = useMemo(
        () => ({
            answers,
            question_id,
            expect_direct_answer,
            proactiveMeta,
        }),
        [answers, expect_direct_answer, question_id, proactiveMeta]
    );

    const answersData = isUninitialized ? lastAnswers : data;

    const isEmpty =
        (answersData?.answers?.length === 0 && isInitial && !answersData?.expect_direct_answer) ||
        (answersData?.answers?.length === 0 && answersData?.proactiveMeta);

    return [
        ask,
        {
            data: answersData,
            isLoading,
            isUninitialized,
            isEmpty,
        },
    ] as const;
};

export const useCurrentAnswer = (): IAnswerResponse | undefined => {
    const { currentAnswerId } = useAppSelector((state) => state.modals.preview);
    const [, { data }] = useAnswers();

    return useMemo(
        () => data?.answers.find((answer) => answer.doc_id === currentAnswerId),
        [currentAnswerId, data?.answers]
    );
};

export const useCurrentMatchUrl = () => {
    const currentAnswer = useCurrentAnswer();
    const [, { data }] = useGetPreviewMutation({
        fixedCacheKey: SHARED_PREVIEW_CACHE_KEY,
    });
    const { currentMatch } = useAppSelector((state) => state.modals.preview);

    return useCallback(
        () => extractUrlFromPreview(currentAnswer, data?.content, currentMatch),
        [currentAnswer, data?.content, currentMatch]
    );
};
