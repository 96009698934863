import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { handleOpenAskAnExpert } from '../../thunks/ask-an-expert-thunk';

export interface IAskAnExpertFormData {
    channel: string;
    userId: string;
    text: string;
    experts?: string[];
    files: File[];
}

interface IASkAnExpertModalState {
    visible: boolean;
    areFilesDraggingOver: boolean;
    initialForm: IAskAnExpertFormData;
}

const initialState: IASkAnExpertModalState = {
    visible: false,
    areFilesDraggingOver: false,
    initialForm: {
        channel: '',
        experts: [],
        userId: '',
        text: '',
        files: [],
    },
};

export const askAnExpertModalSlice = createSlice({
    name: 'ask-an-expert-modal',
    initialState,
    reducers: {
        setFilesDraggingOver(state, action: PayloadAction<boolean>) {
            state.areFilesDraggingOver = action.payload;
        },
        closeAskAnExpertModal(state) {
            state.visible = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(handleOpenAskAnExpert.fulfilled, (state, action) => {
            state.initialForm = action.payload;
            state.visible = true;
        });
    },
});

export const { closeAskAnExpertModal, setFilesDraggingOver } = askAnExpertModalSlice.actions;
