import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    useDisclosure,
} from '@chakra-ui/react';

import { SidebarRefContext } from '../../../components/Sidebar/sidebar-context';

import { DatePickerCalendar } from './Calendar/DatePickerCalendar';

import styles from './DatePickerWidget.module.scss';

export interface DatePickerWidgetProps {
    dateRange: Date[];
    setDateRange: (range: Date[]) => void;
    apply: () => void;
    children: React.ReactNode;
}

export const DatePickerWidgetComponent: React.FC<DatePickerWidgetProps> = ({
    dateRange,
    setDateRange,
    apply,
    children,
}) => {
    const sidebarRef = useContext(SidebarRefContext);
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { t } = useTranslation('translations');
    const [from, to] = dateRange;

    const canBeApplied = useMemo(() => {
        return from && to && from <= to;
    }, [from, to]);

    const renderCustomActions = () => {
        const handleButtonClick = () => {
            apply();
            onClose();
        };

        return (
            <Box className={styles.customActions}>
                <Button variant="primary" isDisabled={!canBeApplied} onClick={handleButtonClick}>
                    {t('dates-picker.apply-button')}
                </Button>
            </Box>
        );
    };

    return (
        <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen} placement="bottom" strategy="fixed">
            <PopoverTrigger>{children}</PopoverTrigger>
            <Portal containerRef={sidebarRef}>
                <PopoverContent>
                    <PopoverBody>
                        <DatePickerCalendar
                            dateRange={dateRange}
                            onDateRangeChange={setDateRange}
                            customActions={renderCustomActions()}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
