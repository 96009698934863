import React from 'react';

import styles from './ListItem.module.scss';

export interface ListItemProps {
    name: string;
    children: React.ReactElement;
}

export const ListItem: React.FC<ListItemProps> = ({ name, children }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.icon}>{children}</div>
            <span className={styles.text}>{name}</span>
        </div>
    );
};
