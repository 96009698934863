export enum MixpanelEvent {
    INSTALL_EXTENSION = 'install_extension',
    UPDATE_EXTENSION = 'update_extension',
    PIN_EXTENSION = 'pin_extension',
    REMOVE_PIN = 'remove_pin',
    ASK = 'ask',
    SOURCE_SELECT = 'source_select',
    SOURCE_DESELECT = 'source_deselect',
    OPEN_SOURCE_FILTER = 'open_filter',
    OPEN_SETTINGS = 'open_settings',
    OPEN_EXTENSION = 'open_extension',
    LOG_OUT_ANSWER = 'log_out_answer',
    SETTINGS_OPEN_LINK_IN_NEW_TAB = 'settings_open_link_in_new_tab',
    CHANGE_CUSTOMER_PROJECT_ID = 'change_customer_project_id',
    OPEN_ASK_AN_EXPERT_DIALOG = 'ask_an_expert_dialog_open',
    ASKED_AN_EXPERT = 'ask_an_expert',
    CANCEL_ASK_AN_EXPERT = 'cancel_ask_an_expert',
    CHANGE_DEFAULT_SHORTCUT = 'change_default_shortcut',
    ASK_AN_EXPERT_OPEN_SLACK = 'open_slack_ask_an_expert',
    THUMB_UP = 'thumb_up',
    THUMB_DOWN = 'thumb_down',
    COPY_LINK = 'copy_link',
    OPEN_ADD_ANSWER_MAIN_PANEL = 'open_add_answer_main_panel',
    ADD_ANSWER = 'add_answer',
    CANCEL_ADD_ANSWER = 'cancel_add_answer',
    COPY_SNIPPET_MAIN_PANEL = 'copy_snippet_main_panel',
    CLOSE_SETTING_PANEL = 'close_setting_panel',
    SHOW_MORE_ANSWERS_MAIN_PANEL = 'show_more_answers_main_panel',
    SHOW_MORE_IN_ANSWER = 'show_more_in_answer',
    OPEN_POINT_EXPLANATION = 'open_point_explanation',
    OPEN_FEEDBACK_MAIN_PANEL = 'open_feedback_main_panel',
    SEND_FEEDBACK = 'send_feedback',
    CANCEL_FEEDBACK = 'cancel_feedback',
    CANCEL_KNOWLEDGE_REQUEST = 'cancel_knowledge_request',
    SEND_KNOWLEDGE_REQUEST = 'send_request_knowledge',
    OPEN_KNOWLEDGE_REQUEST_MAIN_PANEL = 'open_knowledge_request_main_panel',
    VISIT_LINK = 'visit_link',
    USE_SEARCH = 'use_search',
    OPEN_SMART_PREVIEW = 'open_smart_preview',
    CLOSE_SMART_PREVIEW = 'close_smart_preview',
    SEARCH_WITHIN_SMART_PREVIEW = 'search_within_smart_preview',
    CONTACT_SUPPORT = 'contact_support',
    CONTACT_SUPPORT_ERROR_NOTIFICATION = 'contact_support_error_notification',
    AI_HELPER = 'ai_helper',
    DIRECT_ANSWER_START_GENERATE_ANSWER = 'direct_answer_start_generate_answer',
    DIRECT_ANSWER_DONE_GENERATE_ANSWER = 'direct_answer_done_generate_answer',
    DIRECT_ANSWER_CANCEL_REQUEST = 'direct_answer_cancel_request',
    DIRECT_ANSWER_OPEN_CHAT = 'direct_answer_open_chat',
    DIRECT_ANSWER_CLOSE_CHAT = 'direct_answer_close_chat',
    DIRECT_ANSWER_OPEN_EDIT = 'direct_answer_open_edit',
    DIRECT_ANSWER_CLOSE_EDIT = 'direct_answer_close_edit',
    DIRECT_ANSWER_COPY_WITH_ATTRIBUTION = 'direct_answer_copy_with_attribution',
    DIRECT_ANSWER_SHOW_MORE = 'direct_answer_show_more',
    DIRECT_ANSWER_VIEW_ATTRIBUTION_LINK = 'direct_answer_view_attribution_link',
    DIRECT_ANSWER_SHARE_CLICK = 'direct_answer_share_click',
    DIRECT_ANSWER_SHARE_VIEWED = 'direct_answer_share_viewed',
    ACTIVATE_FLOATING_APP = 'activate_floating_app',
    DEACTIVATE_FLOATING_APP = 'deactivate_floating_app',
    WEBSITES_BLACKLIST_OPEN_SETTINGS = 'websites_blacklist_open_settings',
    WEBSITES_BLACKLIST_CLOSE_SETTINGS = 'websites_blacklist_close_settings',
    WEBSITES_BLACKLIST_ADD_WEBSITE = 'websites_blacklist_add_website',
    WEBSITES_BLACKLIST_REMOVE_WEBSITE = 'websites_blacklist_remove_website',
    OPEN_FLOATING_APP = 'open_floating_app',
    CLOSE_FLOATING_APP = 'close_floating_app',
    NOTIFICATION_BANNER_SHOWED = 'notification_banner_showed',
    NOTIFICATION_BANNER_IMAGE_ERROR = 'notification_banner_image_error',
    NOTIFICATION_BANNER_DISMISSED = 'notification_banner_dismissed',
    NOTIFICATION_BANNER_LINK_CLICKED = 'notification_banner_link_clicked',
    DIRECT_ANSWER_COPY = 'direct_answer_copy',
    CLICK_ON_RECENT_SEARCH = 'click_on_recent_search',
    DELETE_RECENT_SEARCH = 'delete_recent_search',
    SET_SIDEBAR_WIDTH = 'set_sidebar_width',
    FLOATING_APP_MOVE = 'floating_app_move',
    EOL_NOTIFICATION_CLOSE_CLICKED = 'eol_notification_close_clicked',
    EOL_NOTIFICATION_GET_IT_NOW_CLICKED = 'eol_notification_get_it_now_clicked',
    FORCE_FEEDBACK_POPUP_OPEN = 'force_feedback_popup_open',
    FORCE_FEEDBACK_POPUP_CLOSED = 'force_feedback_popup_closed',
    PROACTIVE_INITIATED = 'proactive_initiated',
    PROACTIVE_NOT_INITIATED = 'proactive_not_initiated',
    PROACTIVE_CANCELED = 'proactive_canceled',
    PROACTIVE_RECEIVED = 'proactive_received',
    PROACTIVE_RECEIVED_NO_ANSWER = 'proactive_received_no_answer',
    PROACTIVE_GENERATIVE_STARTED = 'proactive_generative_started',
    PROACTIVE_SHOWN = 'proactive_shown',
    PROACTIVE_VIEWED = 'proactive_viewed',
    PROACTIVE_POPUP_CLOSED = 'proactive_popup_closed',
    PROACTIVE_ITEM_ID_CLICKED = 'proactive_item_id_clicked',
    TRAINING_ENABLEMENT_VIEW_MINIMIZED_QUESTION = 'training_enablement_view_minimized_question',
    TRAINING_ENABLEMENT_VIEW_FULL_QUESTION = 'training_enablement_view_full_question',
    TRAINING_ENABLEMENT_ANSWER_QUIZ = 'training_enablement_answer_quiz',
    TRAINING_ENABLEMENT_DISMISS_MINIMIZED_QUESTION = 'training_enablement_dismiss_minimized_question',
    TRAINING_ENABLEMENT_DISMISS_FULL_QUESTION = 'training_enablement_dismiss_full_question',
    TRAINING_ENABLEMENT_OPEN_DOCUMENT = 'training_enablement_open_document',
    AI_HELPER_EXPAND = 'ai_helper_expand',
    AI_HELPER_COLLAPSE = 'ai_helper_collapse',
    AI_HELPER_GROUP_EXPAND = 'ai_helper_group_expand',
    AI_HELPER_GROUP_COLLAPSE = 'ai_helper_group_collapse',
    CHAT_TAB_SEND_MESSAGE = 'chat_tab_send_message',
    CHAT_ABORT_MESSAGE = 'chat_abort_message',
    CHAT_TAB_MESSAGE_FINISHED = 'chat_tab_message_finished',
    CHAT_TAB_ATTRIBUTION_CLICK = 'chat_tab_attribution_click',
    CHAT_VISIT_LINK = 'chat_visit_link',
    SMART_FILTERS_MODAL_ITEM_CLICK = 'smart_filters_modal_item_click',
    SMART_FILTERS_CLEAR_BUTTON = 'smart_filters_clear_button',
}

export enum ITrackEventOrigin {
    ADD_ANSWER = 'add_answer',
    ANSWERS_LIST = 'answers_list',
    ANNOTATE_ANSWER = 'annotate_answer',
    ASK_AN_EXPERT = 'ask_an_expert',
    PREVIEW_WINDOW = 'preview_window',
}

export enum ProactiveGenerativeStartedScenario {
    FLOATING_OPEN = 'floating_open',
    POPUP = 'popup',
}

export enum ProactiveShownScenario {
    FLOATING_OPEN = 'floating_open',
    FLOATING_ANIMATION = 'floating_animation',
    POPUP = 'popup',
    FLOATING_ANIMATION_AND_POPUP = 'floating_animation_and_popup',
    RED_DOT = 'red_dot',
}

export enum ProactiveViewedUserAction {
    FLOATING_IS_OPEN = 'floating_is_open',
    POPUP_CLICK = 'popup_click',
    OPEN_FLOATING = 'open_floating',
}

export interface ITrackEventUser {
    user_id: string;
    user_org: string;
    user_project?: string;
    user_points?: number;
}

export interface ITrackEventData {
    event: MixpanelEvent;
    meta?: MixpanelEventProperties;
    user?: ITrackEventUser;
}

export interface MixpanelEventProperties {
    [key: string]: any;
}

export enum FeatureType {
    APPS = 'APPS',
    GENERATIVE_ANSWER = 'GENERATIVE_ANSWER',
}
