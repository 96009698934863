import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { useSmartFilters } from '../../../../redux/hooks/settings-hooks';
import { openSourcesFilterModal } from '../../../../redux/slices/modals/sources-filter-modal-slice';
import { setFiltersActiveTab } from '../../../../redux/slices/settings/settings-slice';
import { FiltersTabIndex } from '../../../../redux/types';
import { Tooltip } from '../../../Tooltips/Tooltip';

import styles from './SmartFilterChip.module.scss';

export const SmartFilterChip = () => {
    const { t } = useTranslation('translations');
    const { activeSmartFiltersLength } = useSmartFilters();
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(setFiltersActiveTab(FiltersTabIndex.SMART_FILTERS));
        dispatch(openSourcesFilterModal());
    };

    if (activeSmartFiltersLength === 0) {
        return null;
    }

    return (
        <Tooltip label={t('smart-filters.chip.edit-tooltip')}>
            <Box className={styles.smartFiltersChip} onClick={handleClick}>
                <Trans
                    t={t}
                    i18nKey="sources-list.smart-filters-chip.text"
                    values={{ length: activeSmartFiltersLength }}
                />
            </Box>
        </Tooltip>
    );
};
