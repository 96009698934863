import { createSlice } from '@reduxjs/toolkit';

import { IDirectAnswerResult } from '../../../api/types';

interface IDirectAnswerEditModalState {
    visible: boolean;
    data?: {
        conversationId: string;
        originalDirectAnswer?: IDirectAnswerResult;
    };
}

const initialState: IDirectAnswerEditModalState = {
    visible: false,
};

export const directAnswerChatModalSlice = createSlice({
    name: 'direct-answer-chat-modal',
    initialState,
    reducers: {
        openDirectAnswerChatModal(state, action) {
            if (!action.payload) {
                return;
            }
            state.data = action.payload;
            state.visible = true;
        },
        closeDirectAnswerChatModal(state) {
            state.visible = false;
            state.data = undefined;
        },
    },
});

export const { openDirectAnswerChatModal, closeDirectAnswerChatModal } = directAnswerChatModalSlice.actions;
