import { batch } from 'react-redux';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';
import { v4 } from 'uuid';

import packageJson from '../../../package.json';
import i18n from '../../i18n';
import { IRootState } from '../../redux/core-store';
import { closeDirectAnswerChatModal } from '../../redux/slices/modals/direct-answer-chat-modal-slice';
import { closeDirectAnswerEditModal } from '../../redux/slices/modals/direct-answer-edit-modal-slice';
import {
    setActionType,
    setForceFeedbackForQuestion,
    setProactiveMeta,
    setQuestionId,
} from '../../redux/slices/question/question-slice';
import { abortAskQuestion } from '../../redux/thunks/ask-thunk';
import { abortProactiveQuestion } from '../../redux/thunks/proactive-answer-thunk';
import { clearQuestion } from '../../redux/thunks/question-thunk';
import { LAST_ANSWERS_STORAGE_KEY } from '../../services/storage/storage-keys-list';
import { storageService } from '../../services/storage/storage-service';
import { getToastService } from '../../services/toast/toast-service';
import { getUserProject } from '../../utils/user';
import { baseApi } from '../base-api';
import { SUPPORTED_FEATURES } from '../consts';
import {
    ActionType,
    ApiTagTypes,
    IApiAnswersResponse,
    IAskRequestParams,
    IGetAnswers,
    ILastAnswersData,
    ResponseStatus,
} from '../types';

import { prepareAnswers } from './utils/api-answers-utils';

export const EMPTY_RESPONSE: IGetAnswers = {
    answers: [],
    expect_direct_answer: false,
    question_id: '',
    proactiveMeta: null,
};

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.USER],
});

export const askEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        getAnswers: builder.mutation<IGetAnswers, IAskRequestParams>({
            queryFn: async (arg, { getState, dispatch }, _, baseQuery) => {
                const state = getState() as IRootState;
                const { sources, isAiHelper = false, smartFiltersIds, filters } = arg;
                const {
                    settings: { disableOnlineMemory, languageCode },
                    question: { question },
                    uat: { enabled: isUATEnabled },
                } = state;

                dispatch(abortProactiveQuestion());
                dispatch(abortAskQuestion());
                dispatch(closeDirectAnswerChatModal());
                dispatch(closeDirectAnswerEditModal());

                const project = getUserProject(state);

                const actionType = isAiHelper ? ActionType.SEARCH : null;
                batch(() => {
                    dispatch(setActionType({ action_type: actionType, custom_action: null }));
                    dispatch(setProactiveMeta(null));
                });

                const askResponse = await baseQuery({
                    url: '/ask',
                    method: 'POST',
                    body: {
                        question: question.trim(),
                        customer_name: project,
                        customer_project_id: project,
                        selected_sources: sources,
                        client_version: packageJson.version,
                        uat_mode: isUATEnabled,
                        lang_code: languageCode,
                        ...(disableOnlineMemory === true && {
                            max_num_user_feedback_chunks: 0,
                        }),
                        supported_features: SUPPORTED_FEATURES,
                        selected_smart_filters_ids: smartFiltersIds,
                        filters,
                    },
                });

                const { data, error, meta } = askResponse as QueryReturnValue<
                    IApiAnswersResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (meta?.request.signal.aborted) {
                        return { data: EMPTY_RESPONSE };
                    }

                    if (error.status === ResponseStatus.PARSING_ERROR) {
                        // No results were found - BE returns string if no results were found - will return empty data
                        return { data: EMPTY_RESPONSE };
                    }

                    const message = i18n.t('error.api.answers', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    dispatch(clearQuestion());
                    return {
                        error: {
                            status: -1,
                            data: message,
                        },
                    };
                } else if (typeof askResponse.data === 'string') {
                    // No results were found - BE returns string if no results were found - will return empty data
                    dispatch(setQuestionId(''));

                    return { data: EMPTY_RESPONSE };
                } else {
                    const { confidenceSettings } = (getState() as IRootState).settings;
                    const answers = prepareAnswers(data.candidate_answers, confidenceSettings);

                    if (data.direct_answer) {
                        answers.unshift({
                            ...data.direct_answer,
                            uuid: v4(),
                            direct: true,
                            isLowConfidence: false,
                            isGrayedOut: false,
                        });
                    }

                    const questionId = data.question_id || '';

                    await storageService.setStorageValue<ILastAnswersData>(LAST_ANSWERS_STORAGE_KEY, {
                        question: question,
                        answers,
                        question_id: questionId,
                        expect_direct_answer: Boolean(data.expect_direct_answer),
                        action_type: actionType,
                        custom_action: null,
                        proactiveMeta: null,
                        timestamp: Date.now(),
                    });

                    batch(() => {
                        dispatch(setQuestionId(questionId));
                        dispatch(setForceFeedbackForQuestion(true));
                    });

                    return {
                        data: {
                            answers,
                            expect_direct_answer: Boolean(data.expect_direct_answer),
                            question_id: data.question_id || '',
                            proactiveMeta: null,
                        },
                    };
                }
            },
            invalidatesTags: [ApiTagTypes.USER],
        }),
    }),
});

export const { useGetAnswersMutation } = askEndpoint;
