import React from 'react';
import { useFlag } from '@unleash/proxy-client-react';

import { AnswerPreviewType } from '../../../api/types';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { useAppSelector } from '../../../redux/hooks/app-hooks';

import { PreviewModalFooter } from './Footer/PreviewModalFooter';
import { isChatPreview } from './utils';
import { ViewHtml } from './ViewHtml';
import { ViewPdf } from './ViewPdf';

export const Viewer: React.FC = () => {
    const pdfViewerEnabled = useFlag(AppToggle.PDF_VIEWER_ENABLED);
    const { answer } = useAppSelector((state) => state.modals.preview);

    if (!answer) {
        return null;
    }

    const showPdfViewer = isChatPreview(answer) && pdfViewerEnabled && answer.preview_type === AnswerPreviewType.PDF;

    return (
        <>
            {showPdfViewer ? <ViewPdf /> : <ViewHtml />}
            <PreviewModalFooter />
        </>
    );
};
