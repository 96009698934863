import { extendTheme, StyleFunctionProps, SystemStyleObject } from '@chakra-ui/react';
import { theme as chakraDefaultTheme } from '@chakra-ui/theme';

import { CustomCheckbox } from '../../components/Controls/CheckBox/styles';
import { CustomCheckboxThemeKey } from '../../components/Controls/CheckBox/styles';
import { isAnnotateWebMode } from '../../utils/extension-mode';

import { tableTheme } from './table-theme';

import styles from './theme.module.scss';

export const font = 'https://fonts.googleapis.com/css?family=Inter:400,500,600,700';

const withCustomScrollbar = {
    '::-webkit-scrollbar': {
        width: styles.scrollBarWidth,
        background: styles.scrollBarBackground,
    },
    '::-webkit-scrollbar-thumb': {
        background: styles.scrollBarTrack,
        borderRadius: '3px',
    },
};

interface IMainButtonStyles {
    color: string;
    background: string;
    minWidth?: string;
    minHeight?: string;
    _hover: {
        background: string;
        color?: string;
    };
}

interface IOutlinedButtonStyles {
    color: string;
    border: string;
    _hover: {
        background?: string;
        color?: string;
        border?: string;
    };
}

const getMainButtonStyles = ({
    color,
    background,
    _hover,
    minWidth = '100px',
    minHeight = '40px',
}: IMainButtonStyles) => {
    return {
        background: background,
        borderColor: background,
        color: color,
        _hover: {
            background: _hover.background,
            borderColor: _hover.background,
            color: _hover.color || color,
            _disabled: {
                background: _hover.background,
                borderColor: _hover.background,
                color: _hover.color || color,
            },
        },
        minWidth,
        minHeight,
        fontSize: '1.4em',
        fontWeight: 700,
        lineHeight: '22px',
    };
};

const getOutlinedButtonStyles = ({ color, border, _hover }: IOutlinedButtonStyles) => {
    const { color: hoverColor, background: hoverBackground, border: hoverBorder } = _hover;

    return {
        background: styles.surfacePrimary,
        borderColor: border,
        color: color,
        _hover: {
            ...(hoverBackground && { background: hoverBackground }),
            ...(hoverColor && { color: hoverColor }),
            ...(hoverBorder && { borderColor: hoverBorder }),
        },
        borderWidth: '2px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '22px',
        minWidth: '104px',
        height: '36px',
    };
};

const withCustomInputStyles = {
    background: styles.inputBackground,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'rgba(26, 26, 26, 0.2)',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    fontSize: '1.4em',
    color: 'rgba(26, 26, 26, 0.8)',
    _focus: {
        borderColor: styles.hoverBlue,
        borderWidth: '2px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
    },
};

export const getTheme = () => {
    const extended = extendTheme({
        styles: {
            global: () => {
                const globalStyles = chakraDefaultTheme.styles.global as { body: SystemStyleObject } | undefined;

                return {
                    ':host': {
                        ...(globalStyles && {
                            ...globalStyles.body,
                        }),
                    },
                    ':host > *, :host([data-container-size="default"]) > *': {
                        fontSize: 'base.default',
                    },
                    ':host([data-container-size="small"]) > *': {
                        fontSize: 'base.small',
                    },
                    '@media print': {
                        ':host': {
                            visibility: 'hidden',
                        },
                    },
                };
            },
        },
        fonts: {
            heading: styles.fontPrimary,
            body: styles.fontPrimary,
        },
        fontSizes: {
            base: {
                default: '10px',
                small: '9px',
            },
        },
        colors: {
            'light-gray': styles.textLightGray,
            'mid-gray': styles.textMidGray,
            'dark-gray': styles.textDarkGray,
            'blue-gray': styles.blueGray,
            indigo: {
                50: styles.colorIndigo50,
                300: styles.colorIndigo300,
                400: styles.colorIndigo400,
                600: styles.colorIndigo600,
                900: styles.colorIndigo900,
            },
            'text-control': styles.textControl,
            link: styles.linkPrimary,
            'button-search-action': {
                400: '#2962FF',
                500: styles.surfacePrimary,
                600: '#BBDEFB',
            },
            'button-summarize-action': {
                400: '#C51162',
                500: styles.surfacePrimary,
                600: '#F8BBD0',
            },
            'button-rephrase-action': {
                400: '#00897B',
                500: styles.surfacePrimary,
                600: '#B2DFDB',
            },
            'button-close-sidebar': {
                400: '#2785FF',
                500: '#F4F4F4',
                600: '#F4F4F4',
            },
        },
        components: {
            Heading: {
                sizes: {
                    h1: {
                        fontSize: '2em',
                        fontWeight: 500,
                        lineHeight: '24px',
                        color: styles.textPositive,
                    },
                    h2: {
                        fontSize: '1.6em',
                        fontWeight: 600,
                        lineHeight: '24px',
                        color: styles.textLightGray,
                    },
                },
            },
            Button: {
                variants: {
                    primary: getMainButtonStyles({
                        color: styles.surfacePrimary,
                        background: styles.buttonPrimary,
                        _hover: {
                            background: styles.buttonPrimaryHover,
                        },
                    }),
                    secondary: getMainButtonStyles({
                        color: styles.textPositive,
                        background: styles.buttonSecondary,
                        _hover: {
                            background: styles.buttonSecondaryHover,
                        },
                    }),
                    'primary-outlined': getOutlinedButtonStyles({
                        color: styles.buttonPrimaryOutlined,
                        border: styles.buttonPrimaryOutlined,
                        _hover: {
                            background: styles.buttonPrimaryOutlinedHover,
                        },
                    }),
                    'primary-outlined-warning': getOutlinedButtonStyles({
                        color: styles.buttonPrimaryOutlined,
                        border: styles.buttonPrimaryOutlined,
                        _hover: {
                            color: styles.buttonPrimaryOutlinedWarning,
                            border: styles.buttonPrimaryOutlinedWarning,
                            background: styles.buttonPrimaryOutlinedWarningHover,
                        },
                    }),
                    'secondary-outlined': getOutlinedButtonStyles({
                        color: styles.textPositive,
                        border: styles.buttonSecondaryOutlined,
                        _hover: {
                            background: styles.surfacePrimary,
                        },
                    }),
                    'icon-button': {
                        backgroundColor: styles.surfacePrimary,
                        _disabled: {
                            opacity: 0.5,
                        },
                    },
                    'action-primary': getMainButtonStyles({
                        minWidth: '80px',
                        color: styles.surfacePrimary,
                        background: styles.buttonPrimary,
                        _hover: {
                            background: styles.buttonPrimaryHover,
                        },
                    }),
                    'send-message': {
                        bg: '#2785FF',
                        height: '26px',
                        minWidth: '26px',
                        color: 'white',
                        borderRadius: '100%',
                        _disabled: {
                            bg: styles.buttonPrimaryHover,
                            opacity: 1,
                        },
                        _hover: {
                            _disabled: {
                                bg: styles.buttonPrimaryHover,
                            },
                        },
                    },
                    'action-bar': (props: StyleFunctionProps) => {
                        const { colorScheme } = props;

                        return {
                            height: '34px',
                            padding: '7px',
                            width: '34px',
                            minWidth: '34px',
                            borderRadius: '50%',
                            bg: `${colorScheme}.500`,
                            color: styles.actionBarButtonColor,
                            _hover: {
                                color: `${colorScheme}.400`,
                                bg: `${colorScheme}.600`,
                                _disabled: {
                                    color: styles.actionBarButtonColor,
                                },
                            },
                            _active: {
                                bg: `${colorScheme}.600`,
                            },
                        };
                    },
                    'close-sidebar': (props: StyleFunctionProps) => {
                        const { colorScheme } = props;

                        return {
                            height: '30px',
                            padding: '7px',
                            width: '30px',
                            minWidth: '30px',
                            borderRadius: '4px',
                            bg: `${colorScheme}.500`,
                            color: styles.actionBarButtonColor,
                            _hover: {
                                color: `${colorScheme}.400`,
                                bg: `${colorScheme}.600`,
                                _disabled: {
                                    color: styles.actionBarButtonColor,
                                },
                            },
                            _active: {
                                bg: `${colorScheme}.600`,
                            },
                        };
                    },
                    'action-bar-collapse-toggle': {
                        height: '34px',
                        padding: '7px',
                        width: '34px',
                        minWidth: '34px',
                        borderRadius: '50%',
                        bg: '#f4f4f4',
                        _hover: {
                            bg: 'gray.300',
                        },
                        _active: {
                            bg: 'gray.200',
                        },
                    },
                    'annotate-primary': getMainButtonStyles({
                        minWidth: '97px',
                        minHeight: '32px',
                        color: styles.actionSubmitBlue,
                        background: styles.surfacePrimary,
                        _hover: {
                            background: styles.buttonSecondaryHover,
                        },
                    }),
                    'button-tab': {
                        height: '30px',
                        width: '90px',
                        fontSize: '1.2em',
                        lineHeight: '22px',
                        fontWeight: '500',
                        padding: '8px',
                        color: styles.textMidGray,
                        background: styles.buttonSecondary,
                        _hover: {
                            background: styles.buttonSecondaryHover,
                        },
                    },
                    'active-tab': {
                        height: '30px',
                        width: '90px',
                        fontSize: '1.2em',
                        lineHeight: '22px',
                        fontWeight: '500',
                        padding: '8px',
                        color: styles.linkPrimary,
                        background: styles.activeTabBackground,
                    },
                    'bottom-panel': () => {
                        return {
                            minWidth: '50px',
                            height: '32px',
                            color: styles.linkPrimary,
                            fontSize: '1.2em',
                            lineHeight: '22px',
                            fontWeight: '500',
                            padding: '8px',
                            background: styles.buttonSecondary,
                            _hover: {
                                background: styles.buttonSecondaryHover,
                            },
                            _disabled: {
                                color: styles.buttonPrimaryDisabled,
                                _hover: {
                                    color: styles.buttonPrimaryDisabled,
                                },
                            },
                        };
                    },
                    'preview-search': {
                        minWidth: '24px',
                        width: '24px',
                        height: '24px',
                        background: 'none',
                    },
                    'chat-ask-an-expert': {
                        width: 'fit-content',
                        height: 'fit-content',
                        padding: '5px',
                        bg: 'transparent',
                        color: '#2785FF',
                        fontSize: '.9em',
                    },
                    'answers-header-action': {
                        width: '100%',
                        height: '38px',
                        color: styles.linkPrimary,
                        fontSize: '1.2em',
                        lineHeight: '22px',
                        fontWeight: '500',
                        borderRadius: '4px',
                        padding: '8px',
                        background: 'white',
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 15px rgba(0, 0, 0, 0.05)',
                        _hover: {
                            background: styles.buttonSecondaryHover,
                        },
                        _disabled: {
                            color: styles.buttonPrimaryDisabled,
                            _hover: {
                                color: styles.buttonPrimaryDisabled,
                            },
                        },
                    },
                    'help-center-button': getMainButtonStyles({
                        color: styles.blueGray500,
                        background: styles.blueGray20,
                        _hover: {
                            background: styles.blueGray50,
                            color: styles.blueGray600,
                        },
                    }),
                    calendar: {
                        color: '#000',
                        minHeight: '32px',
                        minWidth: '32px',
                        padding: '4px',
                        fontWeight: 400,
                        border: '1px solid transparent',
                        transition: 'none',
                        fontSize: '14px',
                        '.chakra-button__icon': {
                            marginRight: 0,
                            marginLeft: 0,
                        },
                        _disabled: {
                            color: 'gray.300',
                            opacity: 0.5,
                        },
                    },
                    'text-button': {
                        bg: 'transparent',
                        color: 'gray.600',
                        fontSize: 'sm',
                        padding: '4px 8px',
                        transition: 'color 0.2s ease-in-out',
                        _hover: {
                            color: styles.linkPrimary,
                        },
                        _focus: {
                            boxShadow: 'none',
                        },
                        _disabled: {
                            opacity: 0.5,
                            cursor: 'not-allowed',
                        },
                    },
                },
            },
            FormLabel: {
                baseStyle: {
                    fontSize: '1.3em',
                    fontWeight: 700,
                    lineHeight: '22px',
                    marginBottom: 0,
                    color: styles.textMidGray,
                },
            },
            Input: {
                baseStyle: {
                    field: {
                        _disabled: {
                            backgroundColor: styles.backgroundDisabled,
                            opacity: 0.4,
                        },
                    },
                },
                variants: {
                    outline: {
                        field: {
                            height: '40px',
                            ...withCustomInputStyles,
                        },
                    },
                    ask: {
                        field: {
                            pl: '50px',
                        },
                    },
                    disabled: {
                        field: {
                            backgroundColor: styles.inputBackgroundDisabled,
                            color: styles.inputColorDisabled,
                            fontSize: '14px',
                            _disabled: {
                                backgroundColor: styles.inputBackgroundDisabled,
                                opacity: 1,
                                cursor: 'auto',
                            },
                        },
                    },
                    'preview-search': {
                        field: {
                            ...withCustomInputStyles,
                            height: '40px',
                            fontSize: '0.7em',
                        },
                    },
                },
            },
            Select: {
                variants: {
                    outline: {
                        field: {
                            height: '40px',
                            ...withCustomInputStyles,
                        },
                        icon: {
                            color: 'rgba(26, 26, 26, 0.3)',
                        },
                    },
                },
            },
            [CustomCheckboxThemeKey]: CustomCheckbox,
            Textarea: {
                variants: {
                    'message-input': {
                        height: '40px',
                        minHeight: '40px',
                        background: 'transparent',
                        display: 'flex',
                        lineHeight: '1.5',
                        fontSize: '14px',
                        '&::placeholder': {
                            color: styles.textSuggestion,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        },
                    },
                    'search-bar': {
                        fontSize: '1.4em',
                        lineHeight: '1.5',
                        height: '1em',
                        minHeight: '1em',
                        boxSizing: 'content-box',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        transitionDuration: styles.defaultTransitionDuration,
                        transitionProperty: 'padding',

                        '&::placeholder': {
                            color: styles.textSuggestion,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        },

                        '&:placeholder-shown': {
                            textOverflow: 'ellipsis',
                        },
                    },
                    outline: {
                        resize: 'none',
                        minHeight: '136px',
                        ...withCustomInputStyles,
                        ...withCustomScrollbar,
                    },
                    thin: {
                        resize: 'none',
                        minHeight: '64px',
                        ...withCustomInputStyles,
                        ...withCustomScrollbar,
                        _disabled: {
                            backgroundColor: styles.backgroundDisabled,
                            opacity: 0.4,
                        },
                    },
                },
            },
            Link: {
                variants: {
                    main: {
                        color: styles.linkPrimary,
                        fontSize: '1.2em',
                        fontWeight: 700,
                        textDecoration: 'underline',
                    },
                    answer: {
                        color: styles.linkPrimary,
                        fontSize: '1em',
                        fontWeight: 500,
                    },
                    notification: {
                        color: styles.surfacePrimary,
                        fontSize: '1em',
                        fontWeight: 700,
                        textDecoration: 'underline',
                    },
                },
            },
            Modal: {
                baseStyle: {
                    overlay: {
                        position: 'absolute',
                        backgroundColor: styles.overlayBackground,
                        width: '100%',
                    },
                    dialog: {
                        margin: '24px',
                        borderRadius: '16px',
                        fontSize: undefined,
                    },
                    dialogContainer: {
                        position: 'absolute',
                        width: '100%',
                    },
                    header: {
                        borderRadius: '16px 16px 0 0',
                        height: '56px',
                        background:
                            'linear-gradient(0deg, #F6FAFF, #F6FAFF), linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #F5F5F5 68.75%, #F5F5F5 100%)',
                        fontWeight: 500,
                        fontSize: '2em',
                        lineHeight: '24px',
                    },
                    body: {
                        padding: '24px',
                        ...withCustomScrollbar,
                    },
                    footer: {
                        height: '80px',
                        borderTop: `1px solid rgba(137, 153, 177, 0.4)`,
                        gap: '10px',
                        justifyContent: 'center',
                    },
                },
                variants: {
                    preview: {
                        header: {
                            padding: '12px',
                        },
                        footer: {
                            padding: '16px',
                        },
                        dialog: {
                            borderRadius: '16px',
                            minHeight: `calc(${
                                isAnnotateWebMode() ? styles.appHeightWebAnnotate : styles.appHeight
                            } - 48px)`,
                            maxWidth: 'none',
                        },
                    },
                    'preview-sidebar': {
                        header: {
                            padding: '12px',
                        },
                        footer: {
                            padding: '16px',
                        },
                        dialog: {
                            borderRadius: '16px',
                            maxWidth: 'none',
                            height: `calc(100vh - 16px)`,
                            maxHeight: 'none',
                        },
                    },
                    'force-feedback': {
                        dialog: {
                            margin: '10% 8px 8px',
                        },
                        body: {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                    'sources-filter': {
                        dialog: {
                            maxWidth: '520px',
                        },
                        body: {
                            padding: '8px 16px',
                        },
                    },
                    'websites-blacklist': {
                        header: {
                            fontSize: '1.9em',
                        },
                        dialog: {
                            height: '100%',
                        },
                        body: {
                            borderTopWidth: '1px',
                            borderTopColor: styles.borderLightGray,
                            borderTopStyle: 'solid',
                            padding: '0 16px 24px',
                        },
                    },
                },
                sizes: {
                    sidebar: {
                        dialog: {
                            margin: '8px',
                        },
                    },
                },
            },
            Checkbox: {
                baseStyle: {
                    control: {
                        backgroundColor: styles.surfacePrimary,
                        borderColor: styles.textMidGray,
                        color: styles.linkPrimary,
                        _checked: {
                            backgroundColor: styles.surfacePrimary,
                            borderColor: styles.linkPrimary,
                            color: styles.linkPrimary,
                            _hover: {
                                backgroundColor: styles.surfacePrimary,
                                borderColor: styles.linkPrimary,
                                color: styles.linkPrimary,
                                filter: 'grayscale(0.2)',
                            },
                        },
                        _hover: {
                            borderColor: styles.linkPrimary,
                        },
                    },
                    label: {
                        color: styles.textControl,
                        marginStart: '11px',
                        fontWeight: 500,
                        lineHeight: '24px',
                    },
                },
                sizes: {
                    md: {
                        label: {
                            fontSize: '1.4em',
                        },
                    },
                },
            },
            Radio: {
                baseStyle: {
                    control: {
                        borderColor: styles.textMidGray,
                        backgroundColor: styles.surfacePrimary,
                        _checked: {
                            backgroundColor: styles.surfacePrimary,
                            borderColor: styles.linkPrimary,
                            _hover: {
                                borderColor: styles.linkPrimary,
                                backgroundColor: styles.surfacePrimary,
                            },
                            _before: {
                                width: '65%',
                                height: '65%',
                                backgroundColor: styles.linkPrimary,
                            },
                        },
                        _hover: {
                            borderColor: styles.linkPrimary,
                        },
                    },
                    label: {
                        color: styles.textControl,
                        marginStart: '8px',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '24px',
                    },
                },
            },
            Tooltip: {
                baseStyle: {
                    border: `1px solid ${styles.textTooltip}`,
                    borderRadius: '8px',
                    boxShadow: 'none',
                    filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))',
                    color: styles.textTooltipDark,
                    fontFamily: 'body',
                    fontWeight: 500,
                    fontSize: '1.2em',
                    lineHeight: '16px',
                    padding: '8px 9px',
                },
            },
            Popover: {
                baseStyle: {
                    content: {
                        width: 'fit-content',
                        borderColor: styles.textTooltip,
                        color: styles.textControl,
                        borderRadius: '8px',
                        boxShadow: '0 14px 64px -4px rgba(24, 39, 75, 0.12), 0px 8px 22px -6px rgba(24, 39, 75, 0.12)',
                        padding: '8px',
                        cursor: 'default',
                    },
                },
                variants: {
                    'deactivate-menu': {
                        content: {
                            padding: '40px 0 0 0',
                            minWidth: '288px',
                            minHeight: '208px',
                            borderColor: '', // reset style from baseStyle
                            border: styles.floatingComponentBorder,
                            borderRadius: '24px',
                        },
                        body: {
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            padding: '0 8px 16px',
                        },
                    },
                },
            },
            Menu: {
                baseStyle: {
                    list: {
                        border: '1px solid rgba(26, 26, 26, 0.1)',
                        borderRadius: '8px',
                        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
                        marginTop: '18px',
                    },
                    divider: {
                        opacity: '1',
                        borderBottom: '1px solid rgba(26, 26, 26, 0.1)',
                    },
                    item: {
                        color: 'rgba(26, 26, 26, 0.7)',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '24px',
                    },
                },
            },
            Tabs: {
                variants: {
                    line: {
                        tab: {
                            _selected: {
                                color: styles.linkPrimary,
                            },

                            color: styles.textMidGray,
                        },
                        tablist: {
                            borderBottom: '2px solid',
                            borderColor: 'transparent',
                        },
                    },
                },
            },
            Divider: {
                baseStyle: {
                    color: '#e1e1e1',
                },
            },
            Table: tableTheme,
        },
    });

    return extended;
};
