import React from 'react';
import cx from 'clsx';

import { SourcesList } from '../Sources/SourcesList/SourcesList';
import { User } from '../User/User';

import styles from './Status.module.scss';

export interface IStatusProps {
    className?: string;
    hideSources?: boolean;
}

export const Status: React.FC<IStatusProps> = ({ className, hideSources }) => {
    return (
        <div className={cx(styles.wrapper, className)}>
            <div className={styles.left}>{!hideSources && <SourcesList />}</div>
            <div className={styles.right}>
                <User />
            </div>
        </div>
    );
};
