import React from 'react';
import { ModalFooter } from '@chakra-ui/react';
import cx from 'clsx';

import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { isChatPreview } from '../utils';

import { AnswersPreviewFooter } from './AnswersPreviewFooter/AnswersPreviewFooter';
import { BackFooterButton } from './Buttons/Back/BackFooterButton';
import { ChatPreviewFooter } from './ChatPreviewFooter/ChatPreviewFooter';

import styles from './PreviewModalFooter.module.scss';

export const PreviewModalFooter: React.FC = () => {
    const { answer } = useAppSelector((state) => state.modals.preview);

    if (!answer) {
        return null;
    }

    return (
        <ModalFooter className={styles.footer}>
            <div className={cx(styles.left, styles.buttons)}>
                <BackFooterButton />
            </div>
            <div className={cx(styles.right, styles.buttons)}>
                {isChatPreview(answer) ? (
                    <AnswersPreviewFooter answer={answer} />
                ) : (
                    <ChatPreviewFooter answer={answer} />
                )}
            </div>
        </ModalFooter>
    );
};
