import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { anatomy, SystemStyleObject } from '@chakra-ui/theme-tools';

export const CustomCheckboxThemeKey = 'CustomCheckbox';
const parts = anatomy(CustomCheckboxThemeKey).parts('container', 'checkbox', 'text');
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer: SystemStyleObject = {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
    minWidth: '0',
};
const baseStyleCheckBox: SystemStyleObject = {
    display: 'flex',
    flex: '0 0 auto',
    width: '16px',
    aspectRatio: '1',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
};
const baseStyleText: SystemStyleObject = {
    minWidth: '0',
};

const baseStyle = definePartsStyle({
    container: baseStyleContainer,
    checkbox: baseStyleCheckBox,
    text: baseStyleText,
});

export const CustomCheckbox = defineMultiStyleConfig({
    baseStyle,
});
