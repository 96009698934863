import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { ReactComponent as ClearIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { FiltersTabIndex } from '../../../../redux/types';
import { FILTER_KEY, IFormValues } from '../SourcesFilterForm';

export const SearchInput: React.FC = () => {
    const { t } = useTranslation('translations');
    const { values: formValues, setFieldValue } = useFormikContext<IFormValues>();
    const filtersActiveTab = useAppSelector((state) => state.settings.filtersActiveTab);

    const inputValue = formValues[FILTER_KEY];

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            setFieldValue(FILTER_KEY, value);
        },
        [setFieldValue]
    );

    const clearSearch = useCallback(() => {
        setFieldValue(FILTER_KEY, '');
    }, [setFieldValue]);

    const renderRightElement = useCallback(() => {
        if (!!inputValue) {
            return (
                <InputRightElement>
                    <IconButton
                        color="#8999b1"
                        colorScheme="transparent"
                        aria-label={t('select-sources.modal.clear-search-icon-label')}
                        icon={<ClearIcon />}
                        onClick={clearSearch}
                    />
                </InputRightElement>
            );
        }

        return <SearchIcon aria-label={t('select-sources.modal.search-icon-label')} />;
    }, [inputValue, clearSearch, t]);

    return (
        <InputGroup>
            <Input
                size="sm"
                placeholder={
                    filtersActiveTab === FiltersTabIndex.SOURCES
                        ? t('select-sources.modal.search-placeholder')
                        : t('select-smart-filters.modal.search-placeholder')
                }
                value={inputValue}
                onChange={handleChange}
            />
            <InputRightElement>{renderRightElement()}</InputRightElement>
        </InputGroup>
    );
};
