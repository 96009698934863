import { createAsyncThunk } from '@reduxjs/toolkit';

import { askAnExpertEndpoint } from '../../api/endpoints/ask-an-expert-endpoint';
import { IUseSource } from '../../api/types';
import i18n from '../../i18n';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { getLogger } from '../../utils/logger';
import { IRootState } from '../core-store';
import { closeAskAnExpertModal, IAskAnExpertFormData } from '../slices/modals/ask-an-expert-modal-slice';
import { openSuccessModal } from '../slices/modals/success-modal-slice';

import { sendMetrics } from './metrics-thunk';

const logger = getLogger('API');

interface handleAskAnExpertMeta {
    sources: IUseSource[];
    project: string;
}

export const handleAskAnExpertSubmit = createAsyncThunk(
    'modals/ask-an-expert/handleAskAnExpertSubmit',
    async (
        { formData, meta }: { formData: IAskAnExpertFormData; meta: handleAskAnExpertMeta },
        { dispatch, getState }
    ) => {
        const state = getState() as IRootState;
        const { question_id } = state.question;

        try {
            const link = await dispatch(
                askAnExpertEndpoint.endpoints.sendAskAnExpert.initiate({
                    body: {
                        user_id: formData.userId,
                        text: formData.text,
                        question_id,
                        experts: formData.experts ?? [],
                        files: formData.files ?? [],
                    },
                    channel: formData.channel,
                    projectId: meta.project,
                })
            ).unwrap();

            dispatch(
                openSuccessModal({
                    header: i18n.t('ask-an-expert.header', { ns: 'translations' }),
                    message: i18n.t('ask-an-expert.success.message', { ns: 'translations' }),
                    description: i18n.t('ask-an-expert.success.description', { ns: 'translations' }),
                    link: {
                        label: i18n.t('ask-an-expert.success.link', { ns: 'translations' }),
                        clickCallback: () =>
                            dispatch(
                                sendMetrics({
                                    event: MixpanelEvent.ASK_AN_EXPERT_OPEN_SLACK,
                                    meta: {
                                        channel_id: formData.channel,
                                        text: formData.text,
                                        user_id: formData.userId,
                                    },
                                    injectAnswersMetrics: {
                                        sources: meta.sources,
                                    },
                                })
                            ),
                        url: link,
                    },
                })
            );
        } catch (e) {
            logger.error('Error has occurred while asking an expert', e);
        }

        dispatch(closeAskAnExpertModal());
        dispatch(
            sendMetrics({
                event: MixpanelEvent.ASKED_AN_EXPERT,
                meta: {
                    channel_id: formData.channel,
                    text: formData.text,
                    user_id: formData.userId,
                    experts: formData.experts,
                },
                injectAnswersMetrics: {
                    sources: meta.sources,
                },
            })
        );
    }
);

export const closeAskAnExpert = createAsyncThunk(
    'modals/ask-an-expert/closeAskAnExpert',
    async (_, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const isUatMode = state.uat.enabled;

        dispatch(closeAskAnExpertModal());
        dispatch(sendMetrics({ event: MixpanelEvent.CANCEL_ASK_AN_EXPERT, meta: { uat_mode: isUatMode } }));
    }
);

export const handleOpenAskAnExpert = createAsyncThunk(
    'modals/ask-an-expert/handleOpenAskAnExpert',
    async (text: string | undefined, { getState }) => {
        const state = getState() as IRootState;
        const { question } = state.question;
        const { identities } = state.auth.user;

        return {
            userId: identities?.slack_user_id || '',
            channel: '',
            text: text ?? question,
            experts: [],
            files: [],
        };
    }
);
