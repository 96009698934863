import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ModalBody,
    ModalContent as ChakraModalContent,
    ModalFooter,
    ModalHeader,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { Formik } from 'formik';

import { IUseSmartFilter, IUseSource } from '../../../api/types';
import { useAnswers } from '../../../redux/hooks/answer/answer-hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { useSmartFilters, useSources } from '../../../redux/hooks/settings-hooks';
import { closeSourcesFilterModal } from '../../../redux/slices/modals/sources-filter-modal-slice';
import { setFiltersActiveTab } from '../../../redux/slices/settings/settings-slice';
import { getActiveSmartFiltersIds, getActiveSources } from '../../../utils/transforms';

import { ListOfSmartFilters } from './ListOfSmartFilters/ListOfSmartFilters';
import { ListOfSources } from './ListOfSources/ListOfSources';
import { getSelectedSourcesCount } from './helpers';

export const FILTER_KEY = '__FILTER__';

export type IFormValues = {
    [FILTER_KEY]: string;
    sources: IUseSource[];
    smartFilters: IUseSmartFilter[];
};
const initialValues: IFormValues = {
    [FILTER_KEY]: '',
    sources: [],
    smartFilters: [],
};

export const FiltersForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const [answer] = useAnswers();
    const question = useAppSelector((state) => state.question.question);
    const filtersActiveTab = useAppSelector((state) => state.settings.filtersActiveTab);

    const { t } = useTranslation('translations');
    const { sources, disableSources } = useSources();
    const { smartFilters, storeSelectedFilters } = useSmartFilters();

    const handleCloseModal = useCallback(() => {
        dispatch(closeSourcesFilterModal());
    }, [dispatch]);

    const setDisabledSources = useCallback(
        async (sources: IUseSource[]) => {
            const sourcesToDisable = sources.filter((source) => !source.active);

            return disableSources(sourcesToDisable.map((source) => source.id));
        },
        [disableSources]
    );

    const askWithNewSources = useCallback(
        (sources: IUseSource[], smartFilters: IUseSmartFilter[]) => {
            if (!question) {
                return;
            }
            const activeSources = getActiveSources(sources);
            const activeSmartFilters = getActiveSmartFiltersIds(smartFilters);
            answer({ sources: activeSources, smartFiltersIds: activeSmartFilters });
        },
        [question, answer]
    );

    const handleSubmit = useCallback(
        async (formValues: IFormValues) => {
            handleCloseModal();
            const newSources = await setDisabledSources(formValues.sources);
            const newSmartFilters = await storeSelectedFilters(formValues.smartFilters);

            askWithNewSources(newSources, newSmartFilters);
        },
        [handleCloseModal, setDisabledSources, askWithNewSources, storeSelectedFilters]
    );

    const initialFormValues: IFormValues = useMemo(() => {
        return {
            ...initialValues,
            sources: [...sources],
            smartFilters: [...smartFilters],
        };
    }, [sources, smartFilters]);

    return (
        <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
            {({ submitForm, isSubmitting, values }) => {
                const isSubmitDisabled = isSubmitting || getSelectedSourcesCount(values.sources) === 0;

                return (
                    <Tabs
                        index={filtersActiveTab}
                        onChange={(index) => {
                            dispatch(setFiltersActiveTab(index));
                        }}
                    >
                        <ChakraModalContent>
                            <ModalHeader fontSize="16px" h="auto" paddingBottom={0}>
                                {t('filters-modal.header')}
                                <TabList mt="7px" marginInlineStart={'-15px'}>
                                    <Tab fontSize="14px" fontWeight="500">
                                        {t('filters-modal.sources-tab')}
                                    </Tab>
                                    <Tab fontSize="14px" fontWeight="500">
                                        {t('filters-modal.smartFilters-tab')}
                                    </Tab>
                                </TabList>
                            </ModalHeader>
                            <ModalBody p="0" position="relative">
                                <TabPanels>
                                    <TabPanel padding={0}>
                                        <ListOfSources />
                                    </TabPanel>
                                    <TabPanel padding={0}>
                                        <ListOfSmartFilters />
                                    </TabPanel>
                                </TabPanels>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="secondary" onClick={handleCloseModal} isDisabled={isSubmitting}>
                                    {t('button.cancel')}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={submitForm}
                                    isLoading={isSubmitting}
                                    isDisabled={isSubmitDisabled}
                                >
                                    {t('button.apply')}
                                </Button>
                            </ModalFooter>
                        </ChakraModalContent>
                    </Tabs>
                );
            }}
        </Formik>
    );
};
