import { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';

import { useGetActionMutation } from '../../api/endpoints/action-endpoint';
import { useGetAnswersMutation } from '../../api/endpoints/ask-endpoint';
import { useGetProactiveAnswersMutation } from '../../api/endpoints/proactive/proactive-answers-endpoint';
import { settingsEndpoint } from '../../api/endpoints/settings-endpoint';
import { useGetSmartFiltersQuery } from '../../api/endpoints/smart-filters-endpoint';
import { sourcesEndpoint, useGetSourcesQuery, useLazyGetSourcesQuery } from '../../api/endpoints/sources-endpoint';
import { useGetUserQuery, usersEndpoint, useUpdateUserMutation } from '../../api/endpoints/users-endpoint';
import { ISource, IUseSmartFilter, IUseSource, SmartFilter } from '../../api/types';
import { AppToggle } from '../../clients/unleash/app-toggle-names';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { SSOLogoutReason } from '../../services/sso/types';
import { swapElements } from '../../utils/array';
import { getLogger } from '../../utils/logger';
import { getUserProject } from '../../utils/user';
import {
    DEFAULT_SELECTED_SMART_FILTERS,
    SHARED_ANSWERS_CACHE_KEY,
    SHARED_PROACTIVE_ANSWERS_CACHE_KEY,
} from '../constants';
import { AppThunk } from '../core-store';
import { setCurrentUrl, setProactiveSimulateUrl, setSimulateCustomerProject } from '../slices/settings/settings-slice';
import { sendMetrics } from '../thunks/metrics-thunk';
import { clearQuestion } from '../thunks/question-thunk';
import {
    saveBackendUrl,
    setDisabledSources,
    setLanguageCode,
    setProject,
    setSelectedSmartFilters,
} from '../thunks/settings-thunk';
import { logout } from '../thunks/user-thunk';
import { IChangeProject, IUseProjectHook, IUseSourcesHook } from '../types';

import { useAppDispatch, useAppSelector } from './app-hooks';

const logger = getLogger('API');
const settingsLogger = getLogger('SettingsModalComponent');

export const useSources = (): IUseSourcesHook => {
    const disabledSources = useAppSelector((state) => state.settings.disabledSources);
    const { project } = useProject();
    const { data: apiSources } = useGetSourcesQuery(project, { skip: !project });
    const [getSources] = useLazyGetSourcesQuery();
    const dispatch = useAppDispatch();

    const createSourcesList = useCallback((sources: ISource[] | undefined, disabled: string[]): IUseSource[] => {
        return (sources ?? []).map((source) => ({
            ...source,
            active: !disabled.includes(source.id),
        }));
    }, []);

    const sources = useMemo(() => {
        return createSourcesList(apiSources, disabledSources);
    }, [apiSources, createSourcesList, disabledSources]);

    const sourcesMap = useMemo(() => {
        return new Map(sources.map((source) => [source.id, source]));
    }, [sources]);

    const toggleSource: IUseSourcesHook['toggleSource'] = useCallback(
        (sourceId) => {
            const newSources = disabledSources.includes(sourceId)
                ? disabledSources.filter((source) => source !== sourceId)
                : [...disabledSources, sourceId];

            dispatch(setDisabledSources(newSources));

            return createSourcesList(apiSources, newSources);
        },
        [apiSources, createSourcesList, disabledSources, dispatch]
    );

    const disableSources: IUseSourcesHook['disableSources'] = useCallback(
        async (sourceIds) => {
            await dispatch(setDisabledSources(sourceIds));

            return createSourcesList(apiSources, sourceIds);
        },
        [apiSources, createSourcesList, dispatch]
    );

    const lazyGetSources = useCallback(async () => {
        try {
            const result = await getSources(undefined).unwrap();

            return createSourcesList(result, disabledSources);
        } catch (e) {
            logger.error('Error has occurred while fetching the sources', e);
            return [];
        }
    }, [createSourcesList, disabledSources, getSources]);

    return { sources, toggleSource, sourcesMap, lazyGetSources, disableSources };
};

export const useProject = (): IUseProjectHook => {
    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.settings);
    const auth = useAppSelector((state) => state.auth);
    const project = useMemo(() => getUserProject({ auth, settings }), [auth, settings]);
    const { refetch } = useGetSourcesQuery(undefined, { skip: !project });
    const { data: user } = useGetUserQuery(undefined, { skip: auth.token === null });
    const [, { reset: clearAnswers }] = useGetAnswersMutation({
        fixedCacheKey: SHARED_ANSWERS_CACHE_KEY,
    });
    const [, { reset: clearActionAnswers }] = useGetActionMutation({
        fixedCacheKey: SHARED_ANSWERS_CACHE_KEY,
    });

    const setProjectHandler: IUseProjectHook['setProject'] = useCallback(
        async (newProject, params?) => {
            const { skipMetrics = false } = params ?? {};

            if (!user || project === newProject) {
                return;
            }

            await dispatch(setProject(newProject));
            clearAnswers();
            clearActionAnswers();
            refetch();

            if (!skipMetrics) {
                const newProjectName: string = user.customer_projects.find((p) => p.value === newProject)?.text ?? '';

                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.CHANGE_CUSTOMER_PROJECT_ID,
                        meta: {
                            new_project: newProjectName,
                        },
                    })
                );
            }
        },
        [clearActionAnswers, clearAnswers, dispatch, project, refetch, user]
    );

    return {
        project,
        setProject: setProjectHandler,
    };
};

const submitBackendUrl =
    (url: string): AppThunk =>
    (dispatch) => {
        settingsLogger.log('User updated backend URL - logging the user out (without portal)');
        batch(() => {
            dispatch(saveBackendUrl(url));
            dispatch(logout({ logoutFromPortal: false, reason: SSOLogoutReason.CHANGE_BACKEND_URL }));
            dispatch(settingsEndpoint.util.resetApiState());
            dispatch(sourcesEndpoint.util.resetApiState());
            dispatch(usersEndpoint.util.resetApiState());
        });
    };

export const useChangeSettings = () => {
    const dispatch = useAppDispatch();
    const enabledProactive = useFlag(AppToggle.PROACTIVE_ANSWERS);
    const { token } = useAppSelector((state) => state.auth);
    const previousFullTicketAppUrl = useAppSelector((state) => state.settings.fullTicketAppUrl);
    const { setProject } = useProject();
    const { data: user } = useGetUserQuery(undefined, { skip: token === null });
    const [, { reset: clearAnswers }] = useGetAnswersMutation({
        fixedCacheKey: SHARED_ANSWERS_CACHE_KEY,
    });
    const [, { reset: clearActionAnswers }] = useGetActionMutation({
        fixedCacheKey: SHARED_ANSWERS_CACHE_KEY,
    });
    const { backendUrl, disableOnlineMemory, shouldDisplayFloatingApp, simulateCustomerProject } = useAppSelector(
        (state) => state.settings
    );
    const [getProactiveAnswers] = useGetProactiveAnswersMutation({
        fixedCacheKey: SHARED_PROACTIVE_ANSWERS_CACHE_KEY,
    });
    const [updateUser] = useUpdateUserMutation();

    return useCallback(
        async (meta: IChangeProject) => {
            if (meta.backend_url !== backendUrl) {
                return dispatch(submitBackendUrl(meta.backend_url));
            }
            if (!user) {
                return;
            }

            const {
                customer_project,
                disable_online_memory,
                display_floating_app,
                simulate_customer_project,
                lang_code,
                full_ticket_app_url,
                proactive_url,
            } = meta;

            const open_link_in_new_page = meta.open_link_in_new_page ?? user.settings.open_link_in_new_page ?? true;

            const selectedProjectIndex = user.customer_projects.findIndex(
                (project) => project.value === customer_project
            );

            setProject(customer_project);

            if (disable_online_memory !== disableOnlineMemory) {
                clearAnswers();
                clearActionAnswers();
            }

            const newProjectsList = swapElements(user.customer_projects, 0, selectedProjectIndex);

            await updateUser({
                ...user,
                customer_projects: newProjectsList,
                settings: {
                    ...user.settings,
                    open_link_in_new_page: open_link_in_new_page,
                },
            });

            if (open_link_in_new_page !== user.settings.open_link_in_new_page) {
                dispatch(
                    sendMetrics({
                        event: MixpanelEvent.SETTINGS_OPEN_LINK_IN_NEW_TAB,
                        meta: {
                            open_new_link: open_link_in_new_page,
                        },
                    })
                );
            }

            if (display_floating_app !== shouldDisplayFloatingApp) {
                dispatch(
                    sendMetrics({
                        event: display_floating_app
                            ? MixpanelEvent.ACTIVATE_FLOATING_APP
                            : MixpanelEvent.DEACTIVATE_FLOATING_APP,
                        meta: {
                            location: 'Settings',
                        },
                    })
                );
            }

            if (enabledProactive && simulate_customer_project !== simulateCustomerProject) {
                dispatch(setSimulateCustomerProject(simulate_customer_project));
            }

            if (proactive_url && enabledProactive) {
                getProactiveAnswers({ url: proactive_url });
            }

            if (previousFullTicketAppUrl !== full_ticket_app_url) {
                // we should update the proactive simulate url with the full ticket app url
                dispatch(setProactiveSimulateUrl(full_ticket_app_url));

                // in case the full ticket app url is empty, we should clear the question, otherwise we should set the current url
                if (!full_ticket_app_url) {
                    dispatch(setCurrentUrl(window.location.href));
                    dispatch(clearQuestion());
                } else {
                    dispatch(setCurrentUrl(full_ticket_app_url));
                }
            }

            if (lang_code) {
                dispatch(setLanguageCode(lang_code));
            }
        },
        [
            backendUrl,
            clearActionAnswers,
            clearAnswers,
            disableOnlineMemory,
            dispatch,
            enabledProactive,
            previousFullTicketAppUrl,
            simulateCustomerProject,
            setProject,
            shouldDisplayFloatingApp,
            updateUser,
            user,
            getProactiveAnswers,
        ]
    );
};

export const useSmartFilters = () => {
    const isSmartFiltersEnabled = useAppSelector((state) => state.smartFilters.smartFiltersEnabled);
    const selectedSmartFiltersStore = useAppSelector((state) => state.settings.selectedSmartFilters);
    const { project } = useProject();
    const { data: apiFilters, refetch } = useGetSmartFiltersQuery(project, {
        skip: !project || !isSmartFiltersEnabled,
    });
    const dispatch = useAppDispatch();

    const createSmartFiltersList = useCallback(
        (filters: SmartFilter[] | undefined, selectedFilters: IUseSmartFilter[]): IUseSmartFilter[] => {
            return (
                selectedFilters &&
                (filters ?? []).map((filter) => ({
                    ...filter,
                    active: selectedFilters.some((selectedFilter) => selectedFilter?.id?.includes(filter.id)),
                }))
            );
        },
        []
    );

    const smartFilters = useMemo(() => {
        return createSmartFiltersList(apiFilters, selectedSmartFiltersStore);
    }, [apiFilters, createSmartFiltersList, selectedSmartFiltersStore]);

    const storeSelectedFilters = useCallback(
        async (filters: IUseSmartFilter[]) => {
            const selectedSmartFilters = filters.filter((filter) => filter.active);

            await dispatch(setSelectedSmartFilters(selectedSmartFilters));
            return createSmartFiltersList(apiFilters, selectedSmartFilters);
        },
        [apiFilters, createSmartFiltersList, dispatch]
    );

    const clearSmartFilters = async () => {
        await storeSelectedFilters(DEFAULT_SELECTED_SMART_FILTERS);
        await refetch();
    };

    const canUseSmartFilters = smartFilters.length > 0 && isSmartFiltersEnabled;

    const activeSmartFiltersLength = canUseSmartFilters ? smartFilters.filter((filter) => filter.active).length : 0;

    return { smartFilters, storeSelectedFilters, activeSmartFiltersLength, canUseSmartFilters, clearSmartFilters };
};
