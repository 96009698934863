import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DirectAnswerStatus, IDirectAnswerResult, MessageRole } from '../../../api/types';
import { addMessage, clearConversation, setupConversation } from '../../thunks/chat-thunk';
import { IChatState } from '../../types';

const initialState: IChatState = {};
const finishedStatus = [DirectAnswerStatus.DONE, DirectAnswerStatus.FAILED, DirectAnswerStatus.ABORTED];

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        updateLastMessage(state, action: PayloadAction<{ directAnswer: IDirectAnswerResult; question_id: string }>) {
            const { directAnswer, question_id } = action.payload;

            const conversation = state[question_id];

            if (!conversation?.length) {
                return;
            }

            const lastMessageIndex = conversation.length - 1;
            const prevMessage = conversation[lastMessageIndex];
            state[question_id][lastMessageIndex] = {
                ...prevMessage,
                content: directAnswer.answer ?? prevMessage.content,
                directAnswer,
            };
        },
        handleStreamEntry(
            state,
            actions: PayloadAction<{ directAnswer?: IDirectAnswerResult; messageId: string; conversationId: string }>
        ) {
            const { messageId, directAnswer, conversationId } = actions.payload;
            const messageIndex = state[conversationId].findIndex((message) => message.id === messageId);
            if (messageIndex !== -1) {
                // Message already exists in the conversation -> Will update it
                const prevMessage = state[conversationId][messageIndex];
                state[conversationId][messageIndex] = {
                    ...prevMessage,
                    content: directAnswer?.answer ?? '',
                    attributions: directAnswer?.attributions,
                    isTyping: directAnswer?.status && !finishedStatus.includes(directAnswer?.status),
                    isFailed: directAnswer?.status === DirectAnswerStatus.FAILED,
                    directAnswer,
                };
            } else {
                state[conversationId].push({
                    role: MessageRole.ASSISTANT,
                    content: '|',
                    id: messageId,
                    time: new Date(),
                    isTyping: true,
                    isFailed: directAnswer?.status === DirectAnswerStatus.FAILED,
                    directAnswer,
                });
            }
        },
        setAbortMessage(state, actions: PayloadAction<{ messageId: string; conversationId: string }>) {
            const { messageId, conversationId } = actions.payload;
            const messageIndex = state[conversationId].findIndex((message) => message.id === messageId);
            state[conversationId][messageIndex].isTyping = false;
            state[conversationId][messageIndex].isAborted = true;

            if (state[conversationId][messageIndex].directAnswer) {
                state[conversationId][messageIndex].directAnswer!.status = DirectAnswerStatus.ABORTED;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addMessage.fulfilled, (state, action) => {
            const { conversationId, message } = action.payload;
            state[conversationId].push(message);
        });
        builder.addCase(setupConversation.fulfilled, (state, action) => {
            const { conversationId, conversation } = action.payload;
            state[conversationId] = conversation ?? [];
        });
        builder.addCase(clearConversation.fulfilled, (state, action) => {
            const conversationId = action.payload;
            delete state[conversationId];
        });
    },
});

export const { handleStreamEntry, updateLastMessage, setAbortMessage } = chatSlice.actions;
