// Ask endpoint

import { MixpanelEvent } from '../services/mixpanel/types';

export interface IUseSource extends ISource {
    active: boolean;
}

export enum ResponseStatus {
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    PARSING_ERROR = 'PARSING_ERROR',
}

export enum DatesFilterRelativeName {
    ANY_TIME = 'Any Time',
    YESTERDAY = 'Yesterday',
    '7D' = '7D',
    '30D' = '30D',
    '3M' = '3M',
    '6M' = '6M',
    '12M' = '12M',
    CUSTOM = 'Custom',
}
export interface IDateRelativeName {
    relativeName: DatesFilterRelativeName;
}
export interface IDateFilter {
    start_date_iso?: string;
    end_date_iso?: string;
    relative_name: DatesFilterRelativeName;
}

export interface AskFilters {
    dates?: IDateFilter;
}
export interface IAskRequestParams {
    sources: string[];
    smartFiltersIds?: string[];
    isAiHelper?: boolean;
    filters?: AskFilters;
}

export interface IDocumentMetadata {
    created_at: string | null;
    updated_at: string | null;
}

export interface IApiAnswerResponse {
    uuid: string;
    unique_answer_id: string;
    confidence: number;
    doc_id: string;
    title: string;
    html_short: string;
    html_long: string;
    source_icon: string;
    source_id: string;
    source_type: string;
    preview_type: AnswerPreviewType;
    text: string;
    url: string;
    valid_url: string;
    inner_hierarchy?: string[];
    reader_is_article_or_section: boolean;
    source_is_public?: boolean | null;
    document_metadata?: IDocumentMetadata;
}

export enum AnswerPreviewType {
    V_HTML = 'v-html',
    PDF = 'pdf',
}

export interface IAnswerResponse extends IApiAnswerResponse {
    direct: boolean;
    isLowConfidence: boolean;
    isGrayedOut: boolean;
}

export interface IApiAnswersResponse {
    candidate_answers: IApiAnswerResponse[];
    direct_answer?: IApiAnswerResponse;
    question_id?: string;
    expect_direct_answer?: boolean;
}

export interface ILastAnswersData {
    question: string;
    answers: IAnswerResponse[];
    question_id?: string;
    expect_direct_answer?: boolean;
    action_type: ActionType | ActionTypeV2 | null;
    custom_action: IAiHelperUserAction | null;
    proactiveMeta: IProactiveAnswersMeta | null;
    timestamp?: number;
}

// Sources endpoint
export interface IApiSource {
    type: string;
    icon: string;
    tooltip: string;
}

export interface IApiSourcesList {
    [k: string]: IApiSource;
}

export interface ISource extends IApiSource {
    id: string;
}

// Settings endpoint
export interface IApiProactiveFrequencySettingsGroup {
    threshold: number;
    proactive_display_probability: number;
}

export interface IApiProactiveFrequencySettingsTimeouts {
    not_viewed_timeout: number;
    debounce_delay: number;
}

export interface IApiProactiveFrequencySettings {
    groups: Record<string, IApiProactiveFrequencySettingsGroup>;
    timeouts: IApiProactiveFrequencySettingsTimeouts;
}

export interface IApiAIHelpersSettings {
    top_most_apps_number: number;
    top_most_apps_lines_number: number;
    top_most_apps_columns_number: number;
    top_most_groups_number: number;
}

export interface IBaseApiSettings {
    show_low_confidence_tresh: {
        absolute: number;
        gray_out: number;
        relative: number;
    };
    points_explanation_html: string;
    close_smart_preview_after_ms: number;
    ask_ai_project_ids?: string[];
    search_state_relevance_time?: number;
    proactive_frequency: IApiProactiveFrequencySettings;
    ai_helpers?: IApiAIHelpersSettings;
    floating_icon_hide_delay_ms?: number;
    help_center_url?: string;
}

export interface IApiPointBadging {
    [k: string]: {
        badge: string;
        color: string;
    };
}

export interface IApiSettings extends IBaseApiSettings {
    point_badging: IApiPointBadging;
    backend_url: string;
    api_gateway_url?: string;
    ask_ai_project_ids?: string[];
}

export interface ISettings extends IBaseApiSettings {}

// User endpoint
export interface IUserProject {
    text: string;
    value: string;
}

export interface ICompetition {
    questions: number;
    preview_click: number;
    points: number;
    last_updated: string;
    competition_start_date: string;
    competition_end_date: string;
    points_from_first_place: number;
}

export interface IUser {
    uid: string;
    username: string;
    firstname?: string;
    lastname?: string;
    email: string;
    user_org: string;
    role: string;
    customer_projects: IUserProject[];
    competition: ICompetition | null;
    identities: {
        slack_user_id?: string;
    };
    settings: {
        open_link_in_new_page: boolean;
    };
}

export enum DirectAnswerStatus {
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    DONE = 'DONE',
    FAILED = 'FAILED',
    ABORTED = 'ABORTED',
}

export enum MessageStatus {
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    DONE = 'DONE',
    FAILED = 'FAILED',
    ABORTED = 'ABORTED',
}

export enum MessageFailureReason {
    LLM_FAILED = 'LLM_FAILED',
    JSON_PARSE = 'JSON_PARSE',
    LLM_CONTEXT_LIMIT = 'LLM_CONTEXT_LIMIT',
    LLM_INVALID_REQUEST = 'LLM_INVALID_REQUEST',
    LLM_RATE_LIMIT = 'LLM_RATE_LIMIT',
    UNKNOWN = 'UNKNOWN',
    CONNECTION_FAILED = 'CONNECTION_FAILED',
}

export interface IAttributionMeta {
    doc_id: string; // candidate answer doc_id
    title?: string | null;
    source_id?: string | null;
    source_icon?: string | null;
    url?: string | null;
    chunk_id?: string | null;
}

export type IAttributionsAnswer = Pick<
    IAnswerResponse,
    'doc_id' | 'title' | 'source_id' | 'source_icon' | 'url' | 'uuid'
>;

export interface IDirectAnswer {
    status: DirectAnswerStatus;
    answer: string;
    is_answerable: boolean | null;
    action_type: ActionType | null;
    action_id?: string | null;
    time?: Date;
    attributions?: IAttributionMeta[] | null;
    question_id?: string;
}

export interface IDirectAnswerResult extends IDirectAnswer {}

export interface IGetDirectAnswerParams {
    customer_project_id: string;
    question_id?: string;
}

export enum MessageRole {
    USER = 'user',
    ASSISTANT = 'assistant',
}

export enum ChatType {
    TOOL_CHAT = 'tool_chat',
    APP_CHAT = 'app_chat',
}

// Preview endpoint

export enum PreviewType {
    REFERENCE = 'reference',
    PDF = 'pdf',
}
export interface IPreviewResponse {
    content: string;
    type: PreviewType | null;
}

export interface IPreviewRequestParams {
    project: string;
    doc_id: string;
    query: string;
    source_id?: string;
    source_type?: string;
}

export interface ILastPreviewData extends IPreviewRequestParams {
    prevAnswerUuid: string;
    collapsed: boolean;
    timestamp: number;
}

// Annotate answer
export enum AnnotationType {
    ANNOTATE = 'annotation',
    THUMB_UP = 'thumbs up',
    THUMB_DOWN = 'thumbs down',
    CLICK = 'click',
    PREVIEW_CLICK = 'preview_click',
    COPY_LINK = 'copy_url',
    COPY_ANSWER = 'copy_answer',
    READ_MORE = 'read_more',
    SEARCH_PREVIEW = 'search_within_preview',
    SEARCH_PREVIEW_FAILED = 'search_within_preview (failed)',
    SHOW_OTHER_RESULTS = 'show_other_results',
    INCORRECT_INFO = 'incorrect_info_in_doc',
    DID_NOT_ANSWER = 'did_not_answer_question',
    ATTRIBUTION_CLICK = 'attribution_click',
    ATTRIBUTION_INLINE_CLICK = 'attribution_inline_click',
}

export type IAnnotateAnswerResponse = boolean;

export interface IAnnotateAnswerRequestParams {
    question: string;
    url: string;
    title: string;
    answer: string;
    project: string;
    type: AnnotationType;
    isArticleOrSection?: boolean;
    question_id: string;
    isAnswerMissing: boolean;
    isProbablyExists: boolean;
    sources?: IUseSource[];
    additionalData?: string;
    details?: string;
    unique_answer_id?: IApiAnswerResponse['unique_answer_id'];
}

export interface IAddAnswerRequestParams {
    type: AnnotationType;
    answer: string;
    answer_link: string;
    sources: IUseSource[];
    answers: IAnswerResponse[];
}

export interface ISendFeedbackRequestParams {
    answers: IAnswerResponse[];
    project: string;
    message: string;
    sources: IUseSource[];
}

export interface ISendAskAnExpertRequestParams {
    body: {
        question_id: string;
        text: string;
        user_id: string;
        experts?: string[];
        files: File[];
    };
    channel: string;
    projectId: string;
}

export interface IGetChannelMembersParams {
    projectId: string;
    channelId: string;
}

export interface IGetSlackMembersParams {
    projectId: string;
    shouldFetchAllMembers?: boolean;
    slackId?: string;
}

export interface ISendAskAnExpertResponse {
    slack_link: string;
}

export interface ISlackOption {
    value: string;
    label: string;
}

export interface ISlackMember {
    name: string;
    id: string;
    email?: string;
}

export interface ISlackChannelsResponse {
    channels: {
        id: string;
        label: string;
    }[];
}

export interface ISlackMembersResponse {
    members: ISlackMember[];
}

export interface ISlackMemberResponse {
    member: ISlackMember;
}

export interface IRequestKnowledgeParams {
    answers: IAnswerResponse[];
    project: string;
    message: string;
    sources: IUseSource[];
}

// Usage Metrics endpoint
export enum UsageMetricsEventType {
    APPLICATION_OPEN = 'open_extension',
    APPLICATION_LOGOUT = 'clicked_logout',
    SETTINGS_OPEN = 'open_settings_panel',
    CONTACT_SUPPORT = 'contact_support_user_menu',
    CONTACT_SUPPORT_ERROR_NOTIFICATION = 'contact_support_error_notification',
    CHAT_MESSAGE_ABORT = 'chat_message_abort',
}

export interface UsageMetricsProperties {
    [key: string]: any;
}

export interface IUsageMetricsData {
    type: MixpanelEvent | UsageMetricsEventType;
    meta?: UsageMetricsProperties;
}

export enum ApiTagTypes {
    USER = 'User',
    SOURCES = 'Sources',
    DIRECT_ANSWER = 'Direct answer',
    NOTIFICATIONS = 'Notifications',
    AI_HELPER_ACTIONS = 'AiHelperActions',
    SMART_FILTERS = 'SMART_FILTERS',
    CHAT = 'CHAT',
    CONVERSATION = 'CONVERSATION',
}

export interface IProactiveAnswersMeta extends Omit<IProactiveApiMeta, 'url'> {
    item_url: IProactiveApiMeta['url'];
    original_url: IProactiveApiMeta['url'];
}

export interface IGetAnswers {
    answers: IAnswerResponse[];
    question_id: string;
    expect_direct_answer: boolean;
    proactiveMeta: IProactiveAnswersMeta | null;
}

export interface IPreCannedQuestions {
    [key: string]: string[];
}

export enum DirectAnnotationType {
    ThumbsUp = 'ThumbsUp',
    ThumbsDown = 'ThumbsDown',
    CopyAnswer = 'CopyAnswer',
    EditedAnswer = 'EditedAnswer',
}

export interface IAnnotateDirectAnswerRequestParams {
    customerProjectId: string;
    questionId: string;
    annotationType: DirectAnnotationType;
    feedback?: string | null;
    editedData?: string | null;
}

export type IAnnotateDirectAnswerResponse = boolean;

export enum ActionType {
    SEARCH = 'SEARCH',
    SUMMARIZE = 'SUMMARIZE',
    REPHRASE = 'REPHRASE',
}

export enum ActionTypeV2 {
    SEARCH = 'SEARCH',
    CUSTOM = 'CUSTOM',
}

export enum TriggerType {
    TEXT_MARK = 'text_mark',
    SCRAPPING = 'scrapping',
    FULL_WEBPAGE = 'full_webpage',
}

export interface IGetAction {
    question_id: string;
}

export interface IActionRequestParams {
    action_type: ActionType | ActionTypeV2;
    question: string;
    url: string;
    custom_action?: IAiHelperUserAction;
}

export interface ICreateConversationParams {
    customer_project_id: string;
    chat_type: ChatType;
    action_id?: string;
}
export interface ICreateConversationResponse {
    conversation_id: string;
}
export interface IGetConversationParams {
    customer_project_id: string;
    action_id?: string;
}

export interface IConversation {
    conversation_id: string;
    created_at: Date;
    updated_at: Date;
    messages: IMessage[];
}

export interface IConversationResponse {
    conversation: IConversation;
}

export interface ISendMessageParams {
    customer_project_id: string;
    conversation_id: string;
    content: string;
    action_id?: string;
    controller: AbortController;
    selected_sources: string[];
    selected_smart_filters_ids?: string[];
    filters?: AskFilters;
}

export interface ISendMessageBody {
    content: string;
    selected_sources: string[];
    lang_code: string;
    selected_smart_filters_ids?: string[];
    filters?: AskFilters;
}

export interface IMessage {
    message_id: string;
    content: string;
    role: MessageRole;
    turn_id: string;
    attributions?: IAttributionMeta[] | null;
    created_at?: Date;
    updated_at?: Date;
    status?: MessageStatus;
    is_placeholder?: boolean;
}

export interface IMessageEvent extends IMessage {
    status: MessageStatus;
}

export interface IFailureEvent {
    status: MessageStatus;
    error: string;
    reason: MessageFailureReason;
}

export interface IChatResponse {
    question_id: string;
    conversation_id: string;
}

export enum NotificationVersion {
    V1 = 'v1',
    V2 = 'v2',
}

interface INotificationBase<V extends NotificationVersion> {
    id: string;
    full_body_img_url: string;
    created_at: string;
    created_at_timestamp: number;
    version: V;
}

export interface INotificationV1 extends INotificationBase<NotificationVersion.V1> {
    img_link_url?: string;
}

export interface INotificationV2 extends INotificationBase<NotificationVersion.V2> {
    content: string;
    link: string;
    link_text: string;
}

export type Notification = INotificationV1 | INotificationV2;

export interface INotificationApiResponsePayload<T = null> {
    data: T;
    message: string;
    timestamp: number;
}

export interface IProactiveAnswersRequestParams {
    url: string;
}
export enum ProactiveStatus {
    READY = 'READY',
    PENDING = 'PENDING',
}

export enum ProactiveNoAnswerStatus {
    URL_NOT_BELONG = 'URL_NOT_BELONG',
    CANT_GENERATE_QUESTION = 'CANT_GENERATE_QUESTION',
    NO_ANSWERS = 'NO_ANSWERS',
    NOT_ENOUGH_CONFIDENCE = 'NOT_ENOUGH_CONFIDENCE',
}

export interface IProactiveApiMeta {
    url: string;
    item_id: string;
}

export interface IApiProactiveAnswersResponse extends IProactiveApiMeta {
    question_id: string;
    question: string | null;
    candidate_answers: IApiAnswerResponse[];
    expect_direct_answer: boolean;
    direct_answer: string | null;
    status: ProactiveStatus;
    no_answers_status: ProactiveNoAnswerStatus | null;
}

export interface IProactiveAnswersResponse
    extends Omit<IApiProactiveAnswersResponse, 'candidate_answers' | 'direct_answer'> {
    answers: IAnswerResponse[];
}

export interface IProactiveAnswersMockData {
    answers: IAnswerResponse[];
    direct_answer: string;
    item_id: IProactiveApiMeta['item_id'];
}

export interface IApiAnnotateAnswerChunkResponse {
    chunk_id: string;
    doc_id: string;
    title: string;
    source_icon: string;
    source_id: string;
    source_type: string;
    section_titles: string;
    chunk_type: string;
    text: string;
    url: string;
    valid_url: string;
    preview_type: AnswerPreviewType;
}

export interface IAnnotateAnswerChunk extends IApiAnnotateAnswerChunkResponse {}

export interface IApiAnnotateAnswersResponse {
    chunks: IApiAnnotateAnswerChunkResponse[];
    question: string;
    question_id: string;
    tags: string[];
    customer_project_id: string;
    selected_data_source_ids: string[];
}

export interface IAnnotateAnswers extends IApiAnnotateAnswersResponse {
    chunks: IAnnotateAnswerChunk[];
}

export interface IAnnotateAnswersState {
    annotate_answers: IAnnotateAnswers;
    labeled_chunks_map: Record<IAnnotateAnswerChunk['chunk_id'], string>;
    annotated_labeled_chunks_map: Record<IAnnotateAnswerChunk['chunk_id'], string>;
    comment_chunks_map: Record<IAnnotateAnswerChunk['chunk_id'], string>;
    comment_general: string;
    sort_order: string;
    sorted_chunks: IAnnotateAnswers['chunks'];
}

export interface IApiGetAnnotateAnswerRequestBody {
    question: string;
    customer_project_id: string;
    client_version: string;
    selected_data_source_ids: string[];
    tags: string[];
    uat_mode?: boolean;
}

export interface ILabeledAnnotateAnswer extends IApiAnnotateAnswerChunkResponse {
    label: string; // '1' | '2' | '3' | 'NA';
    comment: string;
}

export interface IApiLabelAnnotateAnswersRequestBody extends IApiAnnotateAnswersResponse {
    labeled_chunks: ILabeledAnnotateAnswer[];
    comment: string;
}

export interface IApiAnnotationResult extends IApiLabelAnnotateAnswersRequestBody {
    annotation_id: string;
    created_at: string;
}

export interface IGetAnnotationResultRequestParams {
    annotationId: string;
}

export interface IUATState {
    enabled: boolean;
    forceFeedbackVisible: boolean;
}

export interface IQuizAnswer {
    quiz_answer_id: string;
    quiz_answer_text: string;
}

interface IQuizQuestion {
    quiz_question_id: string;
    document_title: string;
    page_url: string;
    quiz_question_text: string;
    correct_answer: IQuizAnswer;
    wrong_answers: IQuizAnswer[];
}

export interface IProvideQuestionResponse {
    quiz_question: IQuizQuestion | null;
}

export interface ILastTrainingEnablementData {
    question: IProvideQuestionResponse['quiz_question'];
    timestamp: number; // Last displayed timestamp
    count: number; // Number of displayed times
}

// AiHelpers
export interface IApiAiHelperUserAction {
    title: string;
    action_type: ActionTypeV2;
    action_id: string;
    tooltip: string;
    color: string;
    min_chars?: number;
    max_chars?: number;
    icon_url: string;
    trigger_type: TriggerType;
    group: IApiAiHelperGroup | null;
    allowed_domains?: string[];
}

export interface IApiAiHelperGroup {
    group_id: string;
    title: string;
    color: string;
    icon_url: string;
}

export interface IAiHelperUserAction extends IApiAiHelperUserAction {}

export type IApiAiHelperUserActionsResponse = { actions: IApiAiHelperUserAction[] };

interface SmartFilterResponse {
    id: string;
    name: string;
    is_active: boolean;
    created_at: string;
    updated_at: string;
    customer_project_id: string;
    filters: Record<string, any>;
    resources: Record<string, any>;
}

export interface SmartFiltersResponse {
    smartFilters: SmartFilterResponse[];
}

export interface SmartFilter {
    id: string;
    name: string;
}

export interface IUseSmartFilter extends SmartFilter {
    active: boolean;
}

export interface ISmartFiltersState {
    smartFiltersEnabled: boolean;
}
