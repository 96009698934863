import React, { MouseEvent, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Divider, Radio, Text } from '@chakra-ui/react';
import cx from 'clsx';
import { useFormikContext } from 'formik';

import { IUseSmartFilter } from '../../../../api/types';
import { useAppDispatch } from '../../../../redux/hooks/app-hooks';
import { sendMetrics } from '../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../services/mixpanel/types';
import { CheckBox } from '../../../Controls/CheckBox/CheckBox';
import { FILTER_KEY, IFormValues } from '../FiltersForm';
import { SearchInput } from '../SearchInput/SearchInput';

import { ListItem } from './ListItem/ListItem';
import { ReactComponent as FilterIcon } from './filter-icon.svg';

import styles from './ListOfSmartFilters.module.scss';

const MAX_SELECTIONS = 1;

interface ListOfSmartFiltersProps {
    maxSelection?: number;
}
export const ListOfSmartFilters: React.FC<ListOfSmartFiltersProps> = ({ maxSelection = MAX_SELECTIONS }) => {
    const { t } = useTranslation('translations');
    const { values, setFieldValue } = useFormikContext<IFormValues>();
    const dispatch = useAppDispatch();
    const smartFilters = values.smartFilters;

    const filterValue = values[FILTER_KEY].trim();

    const smartFiltersToRender = useMemo(() => {
        if (!filterValue) {
            return smartFilters;
        }

        return smartFilters.filter((filter) => filter.name.toLowerCase().includes(filterValue.toLowerCase()));
    }, [smartFilters, filterValue]);

    const selectedCount = useMemo(() => {
        return smartFilters.filter((filter) => filter.active).length;
    }, [smartFilters]);

    const sendSelectMetrics = useCallback(
        (filterIndex: number) => {
            dispatch(
                sendMetrics({
                    event: MixpanelEvent.SMART_FILTERS_MODAL_ITEM_CLICK,
                    meta: {
                        clickedFilter: { ...smartFilters[filterIndex] },
                    },
                })
            );
        },
        [dispatch, smartFilters]
    );

    const handleSelectedOne = useCallback(
        (filterIdx: number, filters: IUseSmartFilter[]) => {
            filters.forEach((_, idx) => {
                setFieldValue(`smartFilters[${idx}].active`, filterIdx === idx);

                if (filterIdx === idx) {
                    sendSelectMetrics(filterIdx);
                }
            });
        },
        [setFieldValue, sendSelectMetrics]
    );

    const handleSelect = useCallback(
        (filterIdx: number, filters: IUseSmartFilter[]) => {
            const isSelected = filters[filterIdx].active;

            if (selectedCount < maxSelection || isSelected) {
                setFieldValue(`smartFilters[${filterIdx}].active`, !isSelected);
            }

            if (maxSelection === 1) {
                filters.forEach((_, idx) => {
                    setFieldValue(`smartFilters[${idx}].active`, filterIdx === idx ? !isSelected : false);
                });
            }

            sendSelectMetrics(filterIdx);
        },
        [setFieldValue, selectedCount, sendSelectMetrics, maxSelection]
    );

    const shouldCheckboxBeDisabled = useCallback(
        (filterId: string) => {
            return selectedCount === maxSelection && !smartFilters.find((filter) => filterId === filter.id)?.active;
        },
        [smartFilters, maxSelection, selectedCount]
    );

    const handleClearAll = useCallback(() => {
        smartFilters.forEach((_, idx) => {
            setFieldValue(`smartFilters[${idx}].active`, false);
        });
    }, [setFieldValue, smartFilters]);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.selectAllWrapper}>
                    <SearchInput />
                    <div>
                        <div className={styles.selectedWrapper}>
                            <span className={styles.text}>
                                <Trans
                                    t={t}
                                    i18nKey="smart-filters.modal.selected-count"
                                    values={{ selected: selectedCount, total: maxSelection }}
                                />
                            </span>
                            <button className={styles.action} onClick={handleClearAll}>
                                {t('smart-filters.modal.clear-all-button')}
                            </button>
                        </div>
                        {selectedCount === maxSelection && (
                            <Text className={styles.maxSelectedLabel}>
                                {t('smart-filters.modal.chosen-maximum-label')}
                            </Text>
                        )}
                    </div>
                </div>
                <Divider />
            </div>

            <ul className={styles.list}>
                {smartFiltersToRender.map((filterRender, idx, initArray) => {
                    return (
                        <li className={styles.listItem} key={filterRender.id}>
                            {maxSelection === 1 ? (
                                <div className={styles.itemWrapper}>
                                    <Radio
                                        isChecked={filterRender.active}
                                        onChange={() => handleSelect(idx, initArray)}
                                    >
                                        <ListItem name={filterRender.name}>
                                            <FilterIcon />
                                        </ListItem>
                                    </Radio>
                                </div>
                            ) : (
                                <CheckBox
                                    className={cx(
                                        styles.itemWrapper,
                                        shouldCheckboxBeDisabled(filterRender.id) && styles.disabledCheckbox
                                    )}
                                    isChecked={filterRender.active}
                                    onChange={() => handleSelect(idx, initArray)}
                                >
                                    <ListItem name={filterRender.name}>
                                        <FilterIcon />
                                    </ListItem>
                                </CheckBox>
                            )}

                            <button
                                className={cx(styles.action, styles.actionSelectOnly)}
                                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    handleSelectedOne(idx, initArray);
                                }}
                            >
                                {t('smart-filters.modal.item.show-only')}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
